import { RatingBoard, RatingHistory, RatingRevisionType } from '@/enums/rating.enum';
import type { GlobalRatingBoardType } from '@/types/rating/rating.type';

export const SUPPORT_RATING_TYPE_HISTORY = [
  RatingHistory.RATING_VERIFY_PASS,
  RatingHistory.RATING_VERIFY_REJECT,
  RatingHistory.RATING_REVISION_PASS,
  RatingHistory.RATING_REVISION_REJECT,
  RatingHistory.RATING_RECLASSIFICATION,
  RatingHistory.RATING_RECLASSIFICATION_COMMITTEE,
  RatingHistory.RATING_CANCELED,
  RatingHistory.RATING_REJECTED,
  RatingHistory.DIRECT_RATING_REGISTER,
  RatingHistory.DIRECT_RATING_MODIFY,
  RatingHistory.GRAC_RATING_REGISTER,
  RatingHistory.RATING_RECLASSIFICATION_ADJUSTED,
  RatingHistory.RATING_RECLASSIFICATION_COMMITTEE_ADJUSTED,
  RatingHistory.RATING_CANCEL_ADJUSTED,
  RatingHistory.RATING_REJECT_ADJUSTED,
  RatingHistory.GLOBAL_RATING_MODIFY,
  RatingHistory.GLOBAL_RATING_REGISTER,
  RatingHistory.GLOBAL_RATING_DELETE,
  RatingHistory.BUG_FIX_REVISION,
  RatingHistory.GRAC_RATING_DELETE,
  RatingHistory.RATING_DELETE,
  RatingHistory.RATING_OBJECTION_PASS,
  RatingHistory.RATING_OBJECTION_REJECT
];

export const RATING_RESULT_TAB = {
  NONE: 'NONE', // Use for show guide screen
  OBJECTION: 'OBJECTION',
  OBJECTION_IN_PROGRESS: 'OBJECTION_IN_PROGRESS',
  OBJECTION_COMPLETE: 'OBJECTION_COMPLETE',
  OBJECTION_REJECTION: 'OBJECTION_REJECTION',
  DIRECT_INPUT_COMPLETE: 'DIRECT_INPUT_COMPLETE',
  ADJUSTMENT: 'ADJUSTMENT',
  RECLASSIFICATION: 'RECLASSIFICATION',
  CANCEL: 'CANCEL',
  REJECTION: 'REJECTION',
  HISTORY: 'HISTORY',
  PRE_RELEASE: 'PRE_RELEASE',
  SAVE: 'SAVE',
  VERIFYING: 'VERIFYING',
  REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
  VERIFY_REJECTED: 'VERIFY_REJECTED',
  MODIFICATION_VERIFYING: 'MODIFICATION_VERIFYING',
  MODIFICATION_COMPLETE: 'MODIFICATION_COMPLETE',
  MODIFICATION_REJECTION: 'MODIFICATION_REJECTION'
} as const;
export type RatingResultTab = keyof typeof RATING_RESULT_TAB;

export const RATING_RESULT_DETAIL_TAB = {
  ADJUSTMENT: 'ADJUSTMENT',
  RECLASSIFICATION: 'RECLASSIFICATION',
  CANCEL: 'CANCEL',
  REJECTION: 'REJECTION'
};
export type RatingResultDetailTab = keyof typeof RATING_RESULT_DETAIL_TAB;

// Global Rating Options
export const ACB_RATING_OPTIONS = {
  G: '0', // ALL
  PG: 'PG', // ALL
  M: 'M', // ALL
  'MA15+': '15', // 15+
  'R18+': '18' // 18+
} as const;

export type AcbRatingOptionValue = (typeof ACB_RATING_OPTIONS)[keyof typeof ACB_RATING_OPTIONS];

export const CERO_RATING_OPTIONS = {
  A: '0', // ALL
  B: '12', // 12+
  C: '15', // 15+
  D: '17', // 17+
  Z: '18' // 18+
} as const;

export type CeroRatingOptionValue = (typeof CERO_RATING_OPTIONS)[keyof typeof CERO_RATING_OPTIONS];

export const CLASSIND_RATING_OPTIONS = {
  L: '0', // ALL
  10: '10', // 10+
  12: '12', // 12+
  14: '14', // 14+
  16: '16', // 16+
  18: '18' // 18+
} as const;

export type ClassIndRatingOptionValue =
  (typeof CLASSIND_RATING_OPTIONS)[keyof typeof CLASSIND_RATING_OPTIONS];

export const ESRB_RATING_OPTIONS = {
  EVERYONE: '0', // ALL
  'EVERYONE 10+': '10', // 10+
  TEEN: '13', // 13+
  'MATURE 17+': '17', // 17+
  'ADULTS ONLY 18+': '18' // 18+
} as const;

export type EsrbRatingOptionValue = (typeof ESRB_RATING_OPTIONS)[keyof typeof ESRB_RATING_OPTIONS];

export const IARC_RATING_OPTIONS = {
  'Ages 3+': '3', // 3+
  'Ages 7+': '7', // 7+
  'Ages 12+': '12', // 12+
  'Ages 16+': '16', // 16+
  'Ages 18+': '18' // 18+
} as const;

export type IarcRatingOptionValue = (typeof IARC_RATING_OPTIONS)[keyof typeof IARC_RATING_OPTIONS];

export const PEGI1_RATING_OPTIONS = {
  'PEGI 3': '3', // 3+
  'PEGI 7': '7', // 7+
  'PEGI 12': '12', // 12+
  'PEGI 16': '16', // 16+
  'PEGI 18': '18' // 18+
} as const;

export type Pegi1RatingOptionValue =
  (typeof PEGI1_RATING_OPTIONS)[keyof typeof PEGI1_RATING_OPTIONS];

export const PEGI2_RATING_OPTIONS = {
  'PEGI 4': '4', // 4+
  'PEGI 6': '6', // 6+
  'PEGI 12': '12', // 12+
  'PEGI 16': '16', // 16+
  'PEGI 18': '18' // 18+
} as const;

export type Pegi2RatingOptionValue =
  (typeof PEGI2_RATING_OPTIONS)[keyof typeof PEGI2_RATING_OPTIONS];

export const USK_RATING_OPTIONS = {
  'USK 0': '0', // ALL
  'USK 6': '6', // 6+'
  'USK 12': '12', // 12+
  'USK 16': '16', // 16+
  'USK 18': '18' // 18+
} as const;

export type UskRatingOptionValue = (typeof USK_RATING_OPTIONS)[keyof typeof USK_RATING_OPTIONS];

export type GlobalRatingOptionValue =
  | AcbRatingOptionValue
  | CeroRatingOptionValue
  | ClassIndRatingOptionValue
  | EsrbRatingOptionValue
  | IarcRatingOptionValue
  | Pegi1RatingOptionValue
  | Pegi2RatingOptionValue
  | UskRatingOptionValue;

export const GLOBAL_RATING_OPTIONS = {
  [RatingBoard.Acb]: ACB_RATING_OPTIONS,
  [RatingBoard.Cero]: CERO_RATING_OPTIONS,
  [RatingBoard.ClassInd]: CLASSIND_RATING_OPTIONS,
  [RatingBoard.Esrb]: ESRB_RATING_OPTIONS,
  [RatingBoard.Iarc]: IARC_RATING_OPTIONS,
  [RatingBoard.Pegi1]: PEGI1_RATING_OPTIONS,
  [RatingBoard.Pegi2]: PEGI2_RATING_OPTIONS,
  [RatingBoard.Usk]: USK_RATING_OPTIONS
} as const;

export type GlobalRatingOption = (typeof GLOBAL_RATING_OPTIONS)[keyof typeof GLOBAL_RATING_OPTIONS];

export const GLOBAL_RATING_BOARDS: GlobalRatingBoardType[] = [
  {
    boardName: RatingBoard.Esrb,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Esrb],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.ESRB'
  },
  {
    boardName: RatingBoard.Pegi1,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Pegi1],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.PEGI'
  },
  {
    boardName: RatingBoard.Pegi2,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Pegi2],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.PEGI2'
  },
  {
    boardName: RatingBoard.Usk,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Usk],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.USK'
  },
  {
    boardName: RatingBoard.Acb,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Acb],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.ACB'
  },
  {
    boardName: RatingBoard.ClassInd,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.ClassInd],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.CLASS_IND'
  },
  {
    boardName: RatingBoard.Cero,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Cero],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.CERO'
  },
  {
    boardName: RatingBoard.Iarc,
    ratingOption: GLOBAL_RATING_OPTIONS[RatingBoard.Iarc],
    locale: 'studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.intl_org.IARC'
  }
];
// End Global Rating Options

export const GRAC_RADIO = {
  SELF_REVIEW: 'SELF_REVIEW',
  DIRECT_INPUT: 'DIRECT_INPUT'
} as const;

export type GracRadioType = keyof typeof GRAC_RADIO;

export const PLANING_RATTING = {
  AGE_0: '0',
  AGE_12: '12',
  AGE_15: '15',
  AGE_19: '19',
  REJECT: 'REJECT',
  CANCEL: 'CANCEL'
};

export const LINE_RADIO = [
  '1.1.1.1.',
  '1.1.2.1.',
  '1.1.3.1.',
  '1.1.3.2.',
  '1.2.1.1.',
  '1.3.1.1.',
  '1.4.1.1.',
  '1.5.1.1.',
  '2.1.1.1.',
  '2.1.2.1.',
  '2.2.1.1.',
  '2.3.1.1.',
  '3.1.1.1.',
  '3.2.1.1.',
  '3.2.2.1.',
  '4.1.1.1.',
  '4.2.1.1.',
  '5.1.1.1.',
  '6.1.1.1.',
  '7.1.1.1.'
];

export const SURVEY_NEXT = {
  END: 'END'
};

export const RATING_REVISION_TYPE_TO_TRANSLATION = {
  [RatingRevisionType.OTHERS]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_other',
  [RatingRevisionType.BUG_FIX]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.bug_fix',
  [RatingRevisionType.CHANGED_CONTENTS_3]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_background',
  [RatingRevisionType.CHANGED_CONTENTS_4]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_sys_ui',
  [RatingRevisionType.MODIFIED_BILLING_SYSTEM]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_billing_sys',
  [RatingRevisionType.CHANGED_CONTENTS_2]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_mission_scenario',
  [RatingRevisionType.CHANGED_CONTENTS_1]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_char_item',
  [RatingRevisionType.CHANGED_GENRE]:
    'studio.prj_prod_mngmt.rating_build_review.self_review.report_change.changed_info_genre'
} as const;

export const HISTORY = [
  {
    type: 'GRAC_RATING_REGISTER',
    requestMember: {
      memberNo: 70006280,
      nickname: 'stovemaster',
      email: 'stovemaster@smilegate.com'
    },
    createdAt: 1733873745473,
    groupId: '100000006897-1732612493353_group',
    projectId: '554-100000006897-1732612565_pjt',
    productNo: 100891,
    buildId: '6750112b27082bd6519ad1219825f0184bd6deb7',
    buildNo: 1144,
    reason: null,
    productInfoLanguage: {
      KO: {
        language: 'KO',
        productName: 'Hao test rating 123',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_CN: {
        language: 'ZH_CN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_TW: {
        language: 'ZH_TW',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      JA: {
        language: 'JA',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      EN: {
        language: 'EN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      }
    },
    defaultGenreTagInfoLanguage: {
      KO: {
        tagNo: 15,
        language: 'KO',
        tagName: '샌드박스'
      },
      EN: {
        tagNo: 15,
        language: 'EN',
        tagName: 'Sandbox'
      }
    },
    directRatingHistories: null,
    gracRatingHistory: {
      productGracRatingNo: 96,
      issuedAt: 1733823971000,
      ageRating: '0',
      contentInfos: ['VIOLENT', 'GAMBLING'],
      ratingId: '123456',
      verifyRequestAt: 1733823970000,
      verifyFinishedAt: 1733841072000,
      directYn: null,
      userAnswer: {
        surveyVersion: '99.0',
        ageRating: '0',
        contentInfos: ['VIOLENT'],
        fileUrls: [],
        productDescription: '<p>test 1te</p>',
        productControlDescription: 'test 2',
        referenceDownloadLinks: ['', ''],
        createdAt: 1733823971000,
        modifiedAt: 1733823971000,
        memberNo: 100000006897
      },
      adminAnswer: null,
      additionalRating: null
    },
    globalRatingHistories: null,
    hUUID: '044d5693-c30c-4f24-bef4-7c97d55edc2e'
  },
  {
    type: 'GLOBAL_RATING_REGISTER',
    ratingStatus: 'VERIFY_REJECTED',
    requestMember: {
      memberNo: 70006280,
      nickname: 'stovemaster',
      email: 'stovemaster@smilegate.com'
    },
    createdAt: 1733873745473,
    groupId: '100000006897-1732612493353_group',
    projectId: '554-100000006897-1732612565_pjt',
    productNo: 100891,
    buildId: '6750112b27082bd6519ad1219825f0184bd6deb7',
    buildNo: 1144,
    reason:
      '게임명 변경, 개발사명(창작자명) 변경, 대표장르 변경, 배경, 영상 변경, 기본 시스템, UI 변경, 과금 체계 변경, 퀘스트, 시나리오 변경, 캐릭터, 아이템 변경',
    productInfoLanguage: {
      KO: {
        language: 'KO',
        productName: 'Hao test rating 333',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_CN: {
        language: 'ZH_CN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_TW: {
        language: 'ZH_TW',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      JA: {
        language: 'JA',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      EN: {
        language: 'EN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      }
    },
    defaultGenreTagInfoLanguage: {
      KO: {
        tagNo: 15,
        language: 'KO',
        tagName: '샌드박스'
      },
      EN: {
        tagNo: 15,
        language: 'EN',
        tagName: 'Sandbox'
      }
    },
    directRatingHistories: [
      {
        productGracRatingNo: '123',
        issuedAt: 1733823971000,
        ageRating: '0',
        contentInfos: ['VIOLENT', 'GAMBLING'],
        ratingId: '789'
      },
      {
        productGracRatingNo: '123',
        issuedAt: 1733823971000,
        ageRating: '0',
        contentInfos: ['VIOLENT', 'GAMBLING'],
        ratingId: '789'
      }
    ],
    gracRatingHistory: {
      productGracRatingNo: 96,
      issuedAt: 1733823971000,
      ageRating: '0',
      contentInfos: ['VIOLENT'],
      ratingId: '456789',
      verifyRequestAt: 1733823970000,
      verifyFinishedAt: 1733841072000,
      directYn: 'Y',
      userAnswer: {
        surveyVersion: '99.0',
        ageRating: '0',
        contentInfos: ['VIOLENT'],
        fileUrls: [],
        productDescription: '<p>test 1te</p>',
        productControlDescription: 'test 2',
        referenceDownloadLinks: ['', ''],
        createdAt: 1733823981000,
        modifiedAt: 1733823971000,
        memberNo: 100000006897
      },
      adminAnswer: null,
      additionalRating: {
        effectiveAt: 1733923981000
      }
    },
    globalRatingHistories: [
      {
        ratingBoardType: 'ESRB',
        ageRating: '10',
        ratingId: 'RATING_ALL_BDSA',
        contentInfos: ['VIOLENT', 'OBSCENE', 'GAMBLING', 'HORROR', 'VILIFICATION']
      },
      {
        ratingBoardType: 'ACB',
        ageRating: '15',
        ratingId: 'RATING_ALL_BDSA',
        contentInfos: ['VIOLENT', 'OBSCENE', 'GAMBLING', 'HORROR', 'VILIFICATION']
      }
    ],
    hUUID: '044d5693-c30c-4f24-bef4-7c97d55edc2e'
  },
  {
    type: 'GLOBAL_RATING_DELETE',
    ratingStatus: 'VERIFY_REJECTED',
    requestMember: {
      memberNo: 70006280,
      nickname: 'stovemaster',
      email: 'stovemaster@smilegate.com'
    },
    createdAt: 1733873745473,
    groupId: '100000006897-1732612493353_group',
    projectId: '554-100000006897-1732612565_pjt',
    productNo: 100891,
    buildId: '6750112b27082bd6519ad1219825f0184bd6deb7',
    buildNo: 1144,
    reason: null,
    productInfoLanguage: {
      KO: {
        language: 'KO',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_CN: {
        language: 'ZH_CN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      ZH_TW: {
        language: 'ZH_TW',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      JA: {
        language: 'JA',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      },
      EN: {
        language: 'EN',
        productName: 'Hao test rating',
        developer: 'Hao developer',
        publisher: 'Hao distributor'
      }
    },
    defaultGenreTagInfoLanguage: {
      KO: {
        tagNo: 15,
        language: 'KO',
        tagName: '샌드박스'
      },
      EN: {
        tagNo: 15,
        language: 'EN',
        tagName: 'Sandbox'
      }
    },
    directRatingHistories: [
      {
        productGracRatingNo: '123',
        issuedAt: 1733823971000,
        ageRating: '0',
        contentInfos: ['VIOLENT', 'GAMBLING'],
        ratingId: '789'
      },
      {
        productGracRatingNo: '123',
        issuedAt: 1733823971000,
        ageRating: '0',
        contentInfos: ['VIOLENT', 'GAMBLING'],
        ratingId: '789'
      }
    ],
    gracRatingHistory: {
      productGracRatingNo: 96,
      issuedAt: 1733823971000,
      ageRating: '0',
      contentInfos: ['VIOLENT'],
      ratingId: '456789',
      verifyRequestAt: 1733823970000,
      verifyFinishedAt: 1733841072000,
      directYn: 'Y',
      userAnswer: {
        surveyVersion: '99.0',
        ageRating: '0',
        contentInfos: ['VIOLENT'],
        fileUrls: [],
        productDescription: '<p>test 1te</p>',
        productControlDescription: 'test 2',
        referenceDownloadLinks: ['', ''],
        createdAt: 1733823981000,
        modifiedAt: 1733823971000,
        memberNo: 100000006897
      },
      adminAnswer: null,
      additionalRating: null
    },
    globalRatingHistories: [
      {
        ratingBoardType: 'ESRB',
        ageRating: '10',
        ratingId: 'RATING_ALL_BDSA',
        contentInfos: ['VIOLENT', 'OBSCENE', 'GAMBLING', 'HORROR', 'VILIFICATION']
      },
      {
        ratingBoardType: 'ACB',
        ageRating: '15',
        ratingId: 'RATING_ALL_BDSA',
        contentInfos: ['VIOLENT', 'OBSCENE', 'GAMBLING', 'HORROR', 'VILIFICATION']
      }
    ],
    hUUID: '044d5693-c30c-4f24-bef4-7c97d55edc2e'
  }
];

export const SURVEY_MOCK_QA = {
  modify_member_no: null,
  questionnaire: [
    {
      no: '0.',
      contents: [
        {
          no: '0.1.',
          contents: [
            {
              no: '0.1.1.',
              question:
                '신청 게임물 및 게임물에 포함된 콘텐츠에 대한 저작권, 유통권, 초상권 등 정당한 권원을 보유하고 있습니까?',
              description: null,
              id: 926,
              type: '01',
              choice: [
                {
                  next: '927',
                  no: null,
                  img_path: null,
                  name: '예. 보유하고 있습니다.',
                  id: 24,
                  ex_cntn: null
                },
                {
                  next: null,
                  no: null,
                  img_path: null,
                  name: '아니오. 보유하고 있지 않습니다.',
                  id: 25,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '0.1.2.',
              question: '신청 게임물의 서비스 방식을 체크해주세요.',
              description: null,
              id: 927,
              type: '02',
              choice: [
                {
                  next: '928',
                  no: null,
                  img_path: null,
                  name: '인터넷 접속(예. 웹사이트를 통한 계정 로그인, 클라이언트 설치 또는 스트리밍 등)을 통한 서비스',
                  id: 22,
                  ex_cntn: null
                },
                {
                  next: '928',
                  no: null,
                  img_path: null,
                  name: '전용 네트워크(예. PlayStation Network, XBOX Live 등)를 이용한 서비스',
                  id: 23,
                  ex_cntn: null
                },
                {
                  next: '928',
                  no: null,
                  img_path: null,
                  name: '모바일 서비스(예. 구글플레이 등 오픈마켓)',
                  id: 155,
                  ex_cntn: null
                },
                {
                  next: '928',
                  no: null,
                  img_path: null,
                  name: '오프라인 패키지',
                  id: 156,
                  ex_cntn: null
                },
                {
                  next: '928',
                  no: null,
                  img_path: null,
                  name: '기타',
                  id: 157,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '0.1.3.',
              question: '게임 내 유료 구매 요소가 존재합니까?',
              description: null,
              id: 928,
              type: '01',
              choice: [
                {
                  next: '929',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 158,
                  ex_cntn: null
                },
                {
                  next: '930',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 159,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '0.1.3.1.',
              question: '이러한 유료 구매 요소의 방식에 대해 체크해주세요.',
              description: null,
              id: 929,
              type: '01',
              choice: [
                {
                  next: '930',
                  no: null,
                  img_path: null,
                  name: '정액제(계정 유료 결제) 방식',
                  id: 160,
                  ex_cntn: null
                },
                {
                  next: '930',
                  no: null,
                  img_path: null,
                  name: '부분 유료화 방식',
                  id: 161,
                  ex_cntn: null
                },
                {
                  next: '930',
                  no: null,
                  img_path: null,
                  name: '패키지 판매 방식',
                  id: 162,
                  ex_cntn: null
                },
                {
                  next: '930',
                  no: null,
                  img_path: null,
                  name: '기타',
                  id: 163,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '신청 게임물에 대한 정당한 권원 보유 여부, 서비스 방식, 유료화 여부 등을 확인',
          id: '05CO000001',
          title: '정당한 권원 보유 및 제공 방식 확인'
        },
        {
          no: '0.2.',
          contents: [
            {
              no: '0.2.1.',
              question:
                '신청 게임물이 청소년이용불가 등급에 해당하거나 게임산업진흥에 관한 법률 제22조 제2항에 따른 등급분류 거부 대상으로 판단되는 경우, 동법 제21조의8에 따라 직권등급재분류 또는 등급분류 결정 취소 조치가 될 수 있음을 확인해주세요.',
              description: null,
              id: 930,
              type: '01',
              choice: [
                {
                  next: '931',
                  no: null,
                  img_path: null,
                  name: '예. 확인하였습니다.',
                  id: 164,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '0.2.2.',
              question:
                '신청 게임물의 등급분류 결과가 협약한 등급분류 기준에 현저히 위배되거나 자체등급분류사업자 간의 등급분류 결과가 상이한 경우, 게임산업진흥에 관한 법률 제21조의9에 따라 등급 조정 조치가 이루어질 수 있음을 확인해주세요.',
              description: null,
              id: 931,
              type: '01',
              choice: [
                {
                  next: '932',
                  no: null,
                  img_path: null,
                  name: '예. 확인하였습니다.',
                  id: 165,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '0.2.3.',
              question:
                '신청 게임물이 베팅이나 배당을 내용으로 하거나 우연적인 방법으로 결과가 결정되면서 재산상 이익 또는 손실을 주는 사행성게임물에 해당합니까?',
              description: null,
              id: 932,
              type: '01',
              choice: [
                {
                  next: null,
                  no: null,
                  img_path: null,
                  name: '예.',
                  id: 166,
                  ex_cntn: null
                },
                {
                  next: '933',
                  no: null,
                  img_path: null,
                  name: '아니오.',
                  id: 167,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '등급분류받은 내용과 다른 내용 또는 도박의 수단으로 이용·제공하는 것에 대한 금지 조항 확인',
          id: '05CO000002',
          title: '개변조 및 사행행위 이용제공 금지 확인'
        },
        {
          no: '0.3.',
          contents: [
            {
              no: '0.3.1.',
              question:
                '신청 게임물의 내용에 게임산업진흥에 관한 법률 제32조 제2항에 따른 국가정체성을 현저히 손상시키거나, 가족윤리 훼손 등 미풍양속을 해치거나, 사회질서를 문란케 할 정도의 범죄·폭력·음란 묘사 표현 등이 존재합니까?',
              description: null,
              id: 933,
              type: '01',
              choice: [
                {
                  next: null,
                  no: null,
                  img_path: null,
                  name: '예.',
                  id: 168,
                  ex_cntn: null
                },
                {
                  next: '934',
                  no: null,
                  img_path: null,
                  name: '아니오.',
                  id: 169,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '제작·반입 금지 게임물 관련 조항 확인',
          id: '05CO000003',
          title: '불법 게임물 유통 금지 확인'
        }
      ],
      id: 10,
      category: '사전 설문'
    },
    {
      no: '1.',
      contents: [
        {
          no: '1.1.',
          contents: [
            {
              no: '1.1.1.',
              question: '인간형 캐릭터 및 살아있는 생명체를 대상으로 한 폭력 표현이 존재합니까?',
              description:
                '주) 실제 인간 및 인간의 형상을 한 캐릭터, 몬스터(예. 하피, 켄타우로스) 등이 포함됨',
              id: 934,
              type: '01',
              choice: [
                {
                  next: '935',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 45,
                  ex_cntn: null
                },
                {
                  next: '936',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 46,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.1.1.',
              question:
                '이러한 인간형 캐릭터 및 생명체를 대상으로 한 폭력 표현의 정도를 체크해주세요.',
              description:
                '주) 단순, 만화적(전체): 생명체에 대한 폭력이 만화적으로 표현되거나 타격에 따른 단순 이펙트 수준으로 표현된 경우 / 경미한(12세): 생명체에 대한 폭력이 비현실적이며 경미한 수준으로 표현된 경우 / 사실적(15세): 생명체에 대한 공격 표현이 사실적이지만 과도하지 않은 경우 / 과도한(청불): 생명체에 대한 폭력의 묘사가 구체적이고 과도하며 지속적인 경우',
              id: 935,
              type: '01',
              choice: [
                {
                  next: '936',
                  no: null,
                  img_path: null,
                  name: '단순, 만화적 수준',
                  id: 47,
                  ex_cntn: null
                },
                {
                  next: '936',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 48,
                  ex_cntn: null
                },
                {
                  next: '936',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 49,
                  ex_cntn: null
                },
                {
                  next: '936',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 50,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.2.',
              question: '사물에 대한 폭력 표현이 존재합니까?',
              description: '주) 로봇, 비행체, 전투기, 자동차 등 기타 비생물체를 모두 포함함',
              id: 936,
              type: '01',
              choice: [
                {
                  next: '937',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 51,
                  ex_cntn: null
                },
                {
                  next: '938',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 52,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.2.1.',
              question: '이러한 사물에 대한 폭력 표현의 정도를 체크해주세요.',
              description:
                '주) 단순, 만화적(전체): 사물에 대한 폭력이 만화적으로 표현되거나 타격에 따른 단순 이펙트 수준으로 표현된 경우 / 경미한(12세): 사물에 대한 폭력이 존재하나 비교적 경미한 수준으로 표현된 경우',
              id: 937,
              type: '01',
              choice: [
                {
                  next: '938',
                  no: null,
                  img_path: null,
                  name: '단순, 만화적 수준',
                  id: 53,
                  ex_cntn: null
                },
                {
                  next: '938',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 54,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.3.',
              question: '무기류 및 공격의 표현이 존재합니까?',
              description:
                '주) 검/도, 창, 활, 둔기, 지팡이 등의 무기 및 판타지 배경의 마법, 스킬 공격과 실제 세계의 흉기, 총기류 등을 포함한 일체의 무기 및 공격 표현을 포함함',
              id: 938,
              type: '01',
              choice: [
                {
                  next: '939',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 55,
                  ex_cntn: null
                },
                {
                  next: '941',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 56,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.3.1.',
              question: '무기류의 표현 정도를 체크해주세요.',
              description:
                '주) 단순, 만화적(전체): 무기류가 만화적이고 단순하게 표현된 경우 / 경미한(12세): 무기류가 다소 날카롭게 표현된 경우 / 사실적(15세): 무기류가 실제에 가깝게 묘사된 경우',
              id: 939,
              type: '01',
              choice: [
                {
                  next: '940',
                  no: null,
                  img_path: null,
                  name: '단순, 만화적 수준',
                  id: 57,
                  ex_cntn: null
                },
                {
                  next: '940',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 58,
                  ex_cntn: null
                },
                {
                  next: '940',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 59,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.1.3.2.',
              question: '공격(피격)시 표현 정도를 체크해주세요.',
              description:
                '주) 단순, 만화적(전체): 단순 이펙트 수준으로 표현된 경우 / 경미한(12세): 공격 표현이 비현실적이며 경미한 수준인 경우  / 사실적(15세): 공격의 표현이 사실적이지만 과도하지 않은 경우 / 과도한(청불): 공격이 구체적이며 지속적이며 과도하게 표현되는 경우',
              id: 940,
              type: '01',
              choice: [
                {
                  next: '941',
                  no: null,
                  img_path: null,
                  name: '단순, 만화적 수준',
                  id: 60,
                  ex_cntn: null
                },
                {
                  next: '941',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 61,
                  ex_cntn: null
                },
                {
                  next: '941',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 62,
                  ex_cntn: null
                },
                {
                  next: '941',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 63,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '인간 또는 비인간을 대상으로 한 폭력의 묘사, 무기류 및 공격/피격시 폭력의 표현 정도',
          id: '05CO000004',
          title: '무기 및 공격의 표현'
        },
        {
          no: '1.2.',
          contents: [
            {
              no: '1.2.1.',
              question: '타격 및 피격에 따른 선혈의 표현이 존재합니까?',
              description: '주) 색상과 상관없는 모든 종류의 피의 표현을 의미함',
              id: 941,
              type: '01',
              choice: [
                {
                  next: '942',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 64,
                  ex_cntn: null
                },
                {
                  next: '943',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 65,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.2.1.1.',
              question: '선혈의 묘사 정도를 체크해주세요.',
              description:
                '주) 경미한(12세): 만화적으로 표현되었으나 선혈이 구체적으로 표현되지 않은 경우 / 사실적(15세): 사실적인 혈흔 및 선혈의 묘사가 존재하나 과도하지 않은 경우 / 과도한(청불): 붉은 색의 선혈이 구체적이고 지속적으로 표현되는 경우',
              id: 942,
              type: '01',
              choice: [
                {
                  next: '943',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 66,
                  ex_cntn: null
                },
                {
                  next: '943',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 67,
                  ex_cntn: null
                },
                {
                  next: '943',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 68,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '타격 및 피격에 따른 선혈 효과 및 혈흔 등 모든 종류의 피와 관련된 표현',
          id: '05CO000005',
          title: '선혈 표현'
        },
        {
          no: '1.3.',
          contents: [
            {
              no: '1.3.1.',
              question: '공격을 통한 신체 훼손 또는 사체의 표현이 존재합니까?',
              description: '주) 만화적으로 단순하게 희화화되어 표현된 경우는 해당하지 않음',
              id: 943,
              type: '01',
              choice: [
                {
                  next: '944',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 69,
                  ex_cntn: null
                },
                {
                  next: '945',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 70,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.3.1.1.',
              question: '이러한 신체 훼손 또는 사체 표현의 정도를 체크해주세요.',
              description:
                '주) 경미한(12세): 만화적으로 표현되었거나, 단순 배경에만 등장하는 경우 / 사실적(15세): 사실적인 신체 훼손 및 사체 표현이 존재하나 과도하지 않은 경우 / 과도한(청불): 신체 훼손 및 사체 표현이 구체적, 사실적이며 지속적으로 표현되는 경우',
              id: 944,
              type: '01',
              choice: [
                {
                  next: '945',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 71,
                  ex_cntn: null
                },
                {
                  next: '945',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 72,
                  ex_cntn: null
                },
                {
                  next: '945',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 73,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '공격을 통해 생명체의 신체 일부가 절단되거나 불타는 등의 훼손 표현 및 사체의 묘사 정도',
          id: '05CO000006',
          title: '신체 훼손/사체 묘사'
        },
        {
          no: '1.4.',
          contents: [
            {
              no: '1.4.1.',
              question: '이러한 게임에 해당하면서, 게임 내 자원 약탈 요소가 존재합니까?',
              description:
                "주) 다른 유저의 영토에 공격을 가하여 일정 자원(예. 골드, 목재, 광석 등)을 탈취해 오는 행위가 가능한 경우. 다른 유저의 자원에 손실을 가하는 형태로 가져오는 경우 '약탈'에 해당하는 손실로 판단하며, 탈취 성공시 시스템에서 이익을 제공해주는 경우 '약탈'적 손실로 보지 않음",
              id: 945,
              type: '01',
              choice: [
                {
                  next: '946',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 74,
                  ex_cntn: null
                },
                {
                  next: '947',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 75,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.4.1.1.',
              question: '이러한 자원 약탈 요소의 정도를 체크해주세요.',
              description:
                '주) 단순한(전체): 영토 침략시 자원의 손실이 발생할 수 있으나, 약탈이 아닌 형태로 발생하는 경우 / 경미한(12세): 영토 침략시 약탈 형태의 손실이 발생하나, 적절한 수준의 보호장치가 존재하는 경우(예. 약탈 발생시 일정 기간 보호되어 공격을 받지 못하게 하거나, 자원 약탈의 제한이 존재하는 경우)',
              id: 946,
              type: '01',
              choice: [
                {
                  next: '947',
                  no: null,
                  img_path: null,
                  name: '단순한 수준',
                  id: 76,
                  ex_cntn: null
                },
                {
                  next: '947',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 77,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '사회관계망 서비스를 기반으로 한 전략 게임 또는 웹 기반의 전략 게임에서 상대방의 영토를 침략하여 자원을 빼앗아오는 행위',
          id: '05CO000007',
          title: '자원 약탈 요소'
        },
        {
          no: '1.5.',
          contents: [
            {
              no: '1.5.1.',
              question:
                '이러한 게임에 해당하면서, 게임 내 이용자의 캐릭터간 대결 요소가 존재합니까?',
              description:
                '주) 랭킹전, 특정 지역에서 가능한 결투, 난투, 일방적인 Player Killing, 그 밖의 모든 형태의 Player vs Player 요소를 의미함',
              id: 947,
              type: '01',
              choice: [
                {
                  next: '948',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 78,
                  ex_cntn: null
                },
                {
                  next: '949',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 79,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '1.5.1.1.',
              question: '이러한 대결의 결과에 따른 손실의 정도를 체크해주세요.',
              description:
                '주) 단순한(전체): 대결은 가능하나 손실이 없는 경우 / 경미한(12세): 대결에 따른 보상과 손실이 경미하게 발생하는 경우 / 제한적(15세): 약탈이 아닌 형태의 손실이 있고 PvP 안전장치(안전지역, 레벨 제한 등)가 존재하는 경우 / 과도한(청불): 일방적인 PK가 가능하고, 패한 사람의 아이템/경험치 등을 뺴앗아 오는 등 약탈 형태의 손실이 발생하는 경우',
              id: 948,
              type: '01',
              choice: [
                {
                  next: '949',
                  no: null,
                  img_path: null,
                  name: '단순한 수준',
                  id: 80,
                  ex_cntn: null
                },
                {
                  next: '949',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 81,
                  ex_cntn: null
                },
                {
                  next: '949',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 82,
                  ex_cntn: null
                },
                {
                  next: '949',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 83,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '온라인 MMORPG에서 플레이 캐릭터를 이용하여 다른 이용자의 캐릭터와 대결을 벌이는 행위',
          id: '05CO000008',
          title: '이용자간 대결 요소'
        }
      ],
      id: 11,
      category: '폭력성'
    },
    {
      no: '2.',
      contents: [
        {
          no: '2.1.',
          contents: [
            {
              no: '2.1.1.',
              question:
                '캐릭터의 어깨, 허리, 다리 등 가벼운 수준 이상의 노출 표현 또는 타이트한 의상 등 선정적인 의상 표현이 있습니까?',
              description:
                '주) 성적인 맥락과 무관하게 캐릭터의 신체 노출이 경미한 수준으로 표현된 경우 / 노출은 거의 없으나 노골적으로 달라붙는 의상 등을 착용한 경우',
              id: 949,
              type: '01',
              choice: [
                {
                  next: '950',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 84,
                  ex_cntn: null
                },
                {
                  next: '951',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 85,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.1.1.1.',
              question: '이러한 캐릭터의 노출 표현 및 선정적 의상의 표현 정도를 체크해주세요.',
              description:
                '주) 경미한(12세): 성적인 맥락과 무관하게 신체 노출이 경미한 수준으로 표현된 경우(예. 어깨, 허벅지, 다리 등) / 제한적(15세): 타이트한 의상 등 노골적으로 성을 연상시키는 의상 표현 또는 직접적 노출은 아니지만  특정 신체부위를 성적으로 강조하여 묘사한 경우(예. 유방 흔들림, 엉덩이 일부 노출 부각 등) / 직접적(청불): 유두, 둔부, 성기 등 중요부위가 노출되나 음란을 지나치게 묘사하지 않은 경우',
              id: 950,
              type: '01',
              choice: [
                {
                  next: '951',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 86,
                  ex_cntn: null
                },
                {
                  next: '951',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 87,
                  ex_cntn: null
                },
                {
                  next: '951',
                  no: null,
                  img_path: null,
                  name: '직접적 수준',
                  id: 88,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.1.2.',
              question: '캐릭터의 속옷 노출을 포함한 선정적 의상 표현이 있습니까?',
              description:
                '주) 수영장에서의 수영복 노출 등은 통상적인 선정적 의상으로 보지 않으나, 해당 노출의 수준 / 상황 및 맥락을 고려하여 판단',
              id: 951,
              type: '01',
              choice: [
                {
                  next: '952',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 89,
                  ex_cntn: null
                },
                {
                  next: '953',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 90,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.1.2.1.',
              question: '이러한 속옷을 포함한 선정적 노출의 빈도를 체크해주세요.',
              description:
                '주) 가끔(15세): 속옷 노출 표현이 존재하나 일부 캐릭터에서만 과도하지 않은 수준으로 표현된 경우 / 자주(청불): 게임의 주 내용에 캐릭터의 선정적 속옷 노출이 빈번하게 표현되어 있는 경우',
              id: 952,
              type: '01',
              choice: [
                {
                  next: '953',
                  no: null,
                  img_path: null,
                  name: '가끔',
                  id: 91,
                  ex_cntn: null
                },
                {
                  next: '953',
                  no: null,
                  img_path: null,
                  name: '자주',
                  id: 92,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.1.3.',
              question: '성적인 내용을 암시하는 포옹, 가벼운 입맞춤 등의 표현이 있습니까?',
              description:
                '주) 성적 맥락과 관계없는 포옹, 가벼운 입맞춤 등은 성적인 내용을 암시하는 것으로 보지 않음',
              id: 953,
              type: '01',
              choice: [
                {
                  next: '954',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 93,
                  ex_cntn: null
                },
                {
                  next: '954',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 94,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '캐릭터의 선정적인 신체 노출 및 성적인 내용을 암시/연상케 하는 의상 표현',
          id: '05CO000009',
          title: '선정적 신체 노출 및 선정적 의상 등의 표현'
        },
        {
          no: '2.2.',
          contents: [
            {
              no: '2.2.1.',
              question: '게임 내 성적인 암시를 포함한 선정적 언어 표현이 있습니까?',
              description:
                '주) 성적 맥락에서 표현되는 캐릭터 대사, 성행위 및 음란을 표현하는 텍스트 또는 음성 등이 해당됨',
              id: 954,
              type: '01',
              choice: [
                {
                  next: '955',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 95,
                  ex_cntn: null
                },
                {
                  next: '956',
                  no: null,
                  img_path: null,
                  name: '없음',
                  id: 96,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.2.1.1.',
              question: '이러한 선정적 언어 표현의 정도를 체크해주세요.',
              description:
                '주) 이러한 선정적 언어 표현의 수준에 따라 청소년의 성적 욕구를 자극하는지의 여부를 판단',
              id: 955,
              type: '01',
              choice: [
                {
                  next: '956',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 97,
                  ex_cntn: null
                },
                {
                  next: '956',
                  no: null,
                  img_path: null,
                  name: '직접적 수준',
                  id: 98,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '텍스트 또는 음성으로 표현된 선정적 언어',
          id: '05CO000010',
          title: '선정적 언어'
        },
        {
          no: '2.3.',
          contents: [
            {
              no: '2.3.1.',
              question: '캐릭터간 성행위 또는 성행위 암시 표현이 존재합니까?',
              description:
                '주) 성교, 유사 성행위 및 애무, 그 밖의 성행위를 암시하는 모든 표현을 포함하며 이용자의 참여 여부와 관계없음',
              id: 956,
              type: '01',
              choice: [
                {
                  next: '957',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 99,
                  ex_cntn: null
                },
                {
                  next: '958',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 100,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.3.1.1.',
              question: '이러한 성행위 관련 표현의 정도를 체크해주세요.',
              description:
                '주) 제한적(15세): 성행위를 암시하나, 직접적인 묘사가 없는 경우 / 직접적(청불): 성행위를 여과없이 묘사하고 있으나, 게임의 맥락상 등장하는 요소로서 지나치게 음란을 묘사하여 사회질서를 문란케 하는 수준이 아닌 경우',
              id: 957,
              type: '01',
              choice: [
                {
                  next: '958',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 101,
                  ex_cntn: null
                },
                {
                  next: '958',
                  no: null,
                  img_path: null,
                  name: '직접적 수준',
                  id: 102,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '2.3.2.',
              question: '게임 내 성차별, 성폭력, 성매매와 관련된 표현이 존재합니까?',
              description: null,
              id: 958,
              type: '01',
              choice: [
                {
                  next: '959',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 103,
                  ex_cntn: null
                },
                {
                  next: '959',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 104,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '모든 종류의 성행위, 성행위 암시 표현 및 성차별, 성폭력/매매 요소 표현',
          id: '05CO000011',
          title: '성행위 표현'
        }
      ],
      id: 12,
      category: '선정성'
    },
    {
      no: '3.',
      contents: [
        {
          no: '3.1.',
          contents: [
            {
              no: '3.1.1.',
              question: '게임 내 도박 및 베팅/배당 행위를 모사한 표현이 존재합니까?',
              description: '주) 전체이용가는 사행행위 모사 표현이 존재하지 않아야 함',
              id: 959,
              type: '01',
              choice: [
                {
                  next: '960',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 105,
                  ex_cntn: null
                },
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 106,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.1.1.1.',
              question: '이러한 도박행위의 모사 정도를 체크해주세요.',
              description:
                '주) 경미한(12세): 간단한 형태의 내기 표현 또는 우연성 콘텐츠가 존재하는 경우 / 제한적(15세): 게임 내 도박 모사 콘텐츠가 존재하나, 필수적이지 않은 일부 요소로만 존재하는 경우 / 사실적(청불): 도박 모사 콘텐츠가 게임의 주 내용일 경우',
              id: 960,
              type: '01',
              choice: [
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 107,
                  ex_cntn: null
                },
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 108,
                  ex_cntn: null
                },
                {
                  next: '961',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 109,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.1.1.2.',
              question:
                '이러한 도박행위 모사 콘텐츠가 베팅, 배당을 내용으로 하는 카드게임 또는 화투놀이에 해당합니까?',
              description:
                '주) 정보통신망을 통해 제공되는 베팅과 배당을 내용으로 한 카드게임 또는 화투놀이의 경우, 게임머니 유료 판매 여부에 따라 게임산업법 시행령 별표2 제8호의 게임물 관련 사업자 준수사항 대상이 될 수 있음',
              id: 961,
              type: '01',
              choice: [
                {
                  next: '962',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 110,
                  ex_cntn: null
                },
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 111,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.1.1.3.',
              question:
                '이러한 카드게임 또는 화투놀이에서 베팅의 수단이 되는 게임머니를 유료로 판매하고 있습니까?',
              description:
                '주) 아바타 또는 아이템 유료 결제를 통해 베팅성 게임머니를 구매할 수 있는 경우, 아래 시행령 규율대상이 되며 베팅성 게임머니를 유료로 판매하지 않는 경우 해당 대상에서 제외됨',
              id: 962,
              type: '01',
              choice: [
                {
                  next: '963',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 112,
                  ex_cntn: null
                },
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 113,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.1.1.4.',
              question:
                '게임산업진흥에 관한 법률 시행령 별표2 제8호의 게임물 관련 사업자 준수사항 가~사목을 모두 준수하고 있습니까?',
              description:
                '주) 가. 1개월 구매한도 50만원 / 나. 1회 게임 최대 사용 게임머니 5만원 / 다. 1일 최대 손실 게임머니 10만원 / 라. 상대방 선택 금지 / 마. 자동베팅 금지 / 바. 1년마다 본인확인 / 사. 이용자 보호방안 구축',
              id: 963,
              type: '01',
              choice: [
                {
                  next: '964',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 114,
                  ex_cntn: null
                },
                {
                  next: 'END',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 115,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '실제 도박을 표현하거나 베팅/배당 등 사행행위를 모사한 정도\n(내기, 회전판, 화투, 포커, 카지노(슬롯머신, 룰렛 등), 릴게임, 경마(레이스 류), 그 밖의 모든 사행성 유기기구 모사 포함)',
          id: '05CO000012',
          title: '도박 및 사행행위 모사 정도'
        },
        {
          no: '3.2.',
          contents: [
            {
              no: '3.2.1.',
              question:
                '게임 내 아이템 강화, 인챈트 또는 조합 형태의 우연 및 확률 콘텐츠가 존재합니까?',
              description:
                '주) 강화: 재료 아이템에 특정 아이템을 사용하여 그 레벨 및 성능을 향상시키는 경우 / 인챈트: 아이템에 마법적 처리를 하여 능력치를 향상시키는 경우 / 조합: 두 가지의 아이템을 결합하여 새로운 성능의 아이템을 얻는 경우',
              id: 964,
              type: '01',
              choice: [
                {
                  next: '965',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 116,
                  ex_cntn: null
                },
                {
                  next: '966',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 117,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.2.1.1.',
              question:
                '이러한 아이템 강화, 인챈트 또는 조합 콘텐츠 이용에 필요한 아이템을 게임 내에서 획득할 수 있습니까?',
              description:
                "주) '게임 내'라 함은 유료 아이템 구매 등의 형태가 아닌, 이용자의 게임 참여에 의한 결과로 획득 가능한 것을 의미함(예. 몬스터 사냥을 통한 아이템 획득)",
              id: 965,
              type: '01',
              choice: [
                {
                  next: '966',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 118,
                  ex_cntn: null
                },
                {
                  next: '966',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 119,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.2.2.',
              question: '게임 내 확률형 아이템(가챠 등)이 존재합니까?',
              description:
                '주) 확률형 아이템(가챠 등): 개봉 또는 사용시 랜덤하게 그 성능이 결정될 수 있는 랜덤 뽑기형 또는 소모성 아이템을 의미',
              id: 966,
              type: '01',
              choice: [
                {
                  next: '967',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 120,
                  ex_cntn: null
                },
                {
                  next: '968',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 121,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '3.2.2.1.',
              question:
                '이러한 형태의 확률형 아이템을 게임 내에서 획득할 수 있고, 확률 정보를 충분히 공개하고 있습니까?',
              description:
                "주) '게임 내'라 함은 유료 아이템 구매 등의 형태가 아닌, 이용자의 게임 참여에 의한 결과로 획득 가능한 것을 의미함(예. 몬스터 사냥, 퀘스트 수행 등을 통한 아이템 획득)",
              id: 967,
              type: '01',
              choice: [
                {
                  next: '968',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 122,
                  ex_cntn: null
                },
                {
                  next: '968',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 123,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '강화, 인챈트, 조합, 가챠(Gacha) 등 우연 및 확률에 의하여 결정되는 모든 아이템의 존재 유무 및 그 정도 관련 사항',
          id: '05CO000013',
          title: '확률형 아이템'
        },
        {
          no: '3.3.',
          contents: [
            {
              no: '3.3.1.',
              question:
                '현금과 유사한 가치를 지닌 캐시 머니를 통해 이용자간 아이템 등을 거래할 수 있는 시스템이 존재합니까?',
              description:
                '주) 현금으로 구매 가능한 캐시 머니를 이용하여 이용자간 아이템 거래가 가능한 경우(예. 경매장, 거래소 등), 게임 아이템 거래 사이트가 청소년 유해매체로 지정되어 있는 점을 고려, 청소년의 올바른 게임 이용을 저해하는 요소로 판단',
              id: 968,
              type: '01',
              choice: [
                {
                  next: '969',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 124,
                  ex_cntn: null
                },
                {
                  next: '969',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 125,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '그 밖의 사행성과 관련된 사항',
          id: '05CO000014',
          title: '기타 사행성'
        }
      ],
      id: 13,
      category: '사행성'
    },
    {
      no: '4.',
      contents: [
        {
          no: '4.1.',
          contents: [
            {
              no: '4.1.1.',
              question:
                '게임 내 배경, 이미지, 소리, 영상 등을 통해 두려움 또는 무서움을 느끼게 하는 요소가 존재합니까?',
              description: null,
              id: 969,
              type: '01',
              choice: [
                {
                  next: '970',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 126,
                  ex_cntn: null
                },
                {
                  next: '971',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 127,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '4.1.1.1.',
              question: '이러한 두려움 또는 무서움 유발 요소의 정도를 체크해주세요.',
              description:
                '주) 암시적(전체): 단순 배경으로만 표현되는 경우 / 경미한(12세): 음산하고 어두운 분위기가 연출되나 사실적이지 않은 경우 / 사실적(15세): 이용자로 하여금 공포감을 느끼게 하는 요소가 있으나 과도하지 않은 경우 / 과도한(청불): 공포감 유발 요소가 구체적이어서 심리적 긴장 및 불안감을 지속적으로 유발하는 경우',
              id: 970,
              type: '01',
              choice: [
                {
                  next: '971',
                  no: null,
                  img_path: null,
                  name: '암시적 수준',
                  id: 128,
                  ex_cntn: null
                },
                {
                  next: '971',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 129,
                  ex_cntn: null
                },
                {
                  next: '971',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 130,
                  ex_cntn: null
                },
                {
                  next: '971',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 131,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '배경, 이미지, 소리, 영상 등을 통해 이용자로 하여금 위협이나 위험을 느껴 불안하게 하는 정도',
          id: '05CO000015',
          title: '두려움, 무서움 유발 요소'
        },
        {
          no: '4.2.',
          contents: [
            {
              no: '4.2.1.',
              question:
                '게임 내 배경, 이미지, 소리, 영상 등을 통해 혐오감을 느끼게 하는 요소가 존재합니까?',
              description: null,
              id: 971,
              type: '01',
              choice: [
                {
                  next: '972',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 132,
                  ex_cntn: null
                },
                {
                  next: '973',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 133,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '4.2.1.1.',
              question: '이러한 혐오감 유발 요소의 정도를 체크해주세요.',
              description:
                '주) 암시적(전체): 단순 배경으로만 표현되는 경우 / 경미한(12세): 혐오감 유발 요소가 존재하나 사실적이지 않은 경우 / 사실적(15세): 이용자로 하여금 혐오감을 느끼게 하는 요소가 있으나 과도하지 않은 경우 / 과도한(청불): 혐오감 유발 요소가 구체적, 노골적으로 표현된 경우',
              id: 972,
              type: '01',
              choice: [
                {
                  next: '973',
                  no: null,
                  img_path: null,
                  name: '암시적 수준',
                  id: 134,
                  ex_cntn: null
                },
                {
                  next: '973',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 135,
                  ex_cntn: null
                },
                {
                  next: '973',
                  no: null,
                  img_path: null,
                  name: '사실적 수준',
                  id: 136,
                  ex_cntn: null
                },
                {
                  next: '973',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 137,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '배경, 이미지, 소리, 영상 등을 통해 이용자로 하여금 싫거나 역겹게 느껴지게 하는 정도',
          id: '05CO000016',
          title: '혐오감 유발 요소'
        }
      ],
      id: 14,
      category: '공포'
    },
    {
      no: '5.',
      contents: [
        {
          no: '5.1.',
          contents: [
            {
              no: '5.1.1.',
              question: '게임 내 저속한 농담, 은어, 비속어, 욕설의 표현이 존재합니까?',
              description:
                '주) bastard, god damn, asshole, shit, son of a bitch, fuck, motherfucker 등의 영어 비속어 및 욕설, 그 밖의 외국어 욕설 및 한글화된 모든 욕설을 포함함',
              id: 973,
              type: '01',
              choice: [
                {
                  next: '974',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 138,
                  ex_cntn: null
                },
                {
                  next: '975',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 139,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '5.1.1.1.',
              question: '이러한 저속한 농담, 은어, 비속어, 욕설 표현의 정도를 체크해주세요',
              description:
                '주) 경미한(12세): 가벼운 욕설, 일상적 비속어, 인터넷 은어 등(젠장, 멍청이) / 제한적(15세): 욕설 표현이 있으나 반복적으로 등장하지 않으며, 15세 이상의 사람에 용인되는 수준(예. 빌어먹을, 미친X, X자식, 꼬라지, 대갈통) / 과도한(청불): 직접적, 구체적 또는 반복적 욕설로서 청소년의 올바른 언어습관에 매우 부정적인 영향을 줄 수 있는 경우',
              id: 974,
              type: '01',
              choice: [
                {
                  next: '975',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 140,
                  ex_cntn: null
                },
                {
                  next: '975',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 141,
                  ex_cntn: null
                },
                {
                  next: '975',
                  no: null,
                  img_path: null,
                  name: '과도한 수준',
                  id: 142,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '모욕적이고 무례하거나 차별적인 언어로서, 청소년의 건강한 언어생활을 저해할 수 있는 부적절한 언어 표현(캐릭터의 대사, 음성 및 중간 시네마틱 영상 등을 포함)',
          id: '05CO000017',
          title: '부적절한 언어 표현'
        }
      ],
      id: 15,
      category: '언어'
    },
    {
      no: '6.',
      contents: [
        {
          no: '6.1.',
          contents: [
            {
              no: '6.1.1.',
              question:
                '게임 내 술 및 알코올 음료의 음주 또는 담배등의 흡연과 관련된 표현이 존재합니까?',
              description: '주) NPC(Non-player character)의 음주 및 흡연 표현을 포함함',
              id: 975,
              type: '01',
              choice: [
                {
                  next: '976',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 143,
                  ex_cntn: null
                },
                {
                  next: '977',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 144,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '6.1.1.1.',
              question: '이러한 음주 및 흡연 표현의 정도에 대해 체크해주세요.',
              description:
                '주) 경미한(12세): 단순 배경으로 등장하거나 상호작용이 불가능한 경우 / 제한적(15세): 비중이 매우 적은 콘텐츠로서 이용자의 선택에 따라 음주 및 흡연 표현이 일부 가능한 경우 / 직접적(청불): 직접적으로 흡연 및 음주를 표현하였거나 이용자의 의지에 따른 상호작용이 자유로이 가능한 경우',
              id: 976,
              type: '01',
              choice: [
                {
                  next: '977',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 145,
                  ex_cntn: null
                },
                {
                  next: '977',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 146,
                  ex_cntn: null
                },
                {
                  next: '977',
                  no: null,
                  img_path: null,
                  name: '직접적 수준',
                  id: 147,
                  ex_cntn: null
                }
              ]
            }
          ],
          description: '음주 및 흡연과 관련된 모든 종류의 표현 및 이용자의 참여 가능 정도',
          id: '05CO000018',
          title: '음주 및 흡연 표현'
        },
        {
          no: '6.2.',
          contents: [
            {
              no: '6.2.1.',
              question: '게임 내 마약류와 관련된 표현이 존재합니까?',
              description:
                '주) NPC 및 플레이어의 행위를 포함하여 게임 내에서 표현되는 모든 형태의 마약 사용, 거래를 의미함',
              id: 977,
              type: '01',
              choice: [
                {
                  next: '978',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 148,
                  ex_cntn: null
                },
                {
                  next: '978',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 149,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '환각을 일으키는 마약류, 본드, 가스 등의 흡입 또는 그 밖의 유사한 환각효과를 일으키는 약물 복용 표현을 의미',
          id: '05CO000019',
          title: '마약류 표현'
        }
      ],
      id: 16,
      category: '약물'
    },
    {
      no: '7.',
      contents: [
        {
          no: '7.1.',
          contents: [
            {
              no: '7.1.1.',
              question: '게임 내 범죄에 대한 묘사가 존재합니까?',
              description: '주) NPC의 범죄 표현을 포함함',
              id: 978,
              type: '01',
              choice: [
                {
                  next: '979',
                  no: null,
                  img_path: null,
                  name: '예',
                  id: 150,
                  ex_cntn: null
                },
                {
                  next: 'END',
                  no: null,
                  img_path: null,
                  name: '아니오',
                  id: 151,
                  ex_cntn: null
                }
              ]
            },
            {
              no: '7.1.1.1.',
              question: '해당 범죄 묘사의 정도에 대해 체크해주세요.',
              description:
                '주) 경미한(12세): 이용자의 의도와 관계없는 단순 배경만으로 묘사 / 제한적(15세): 게임 내 차지하는 비중이 적고 제한적으로 표현된 경우 / 직접적(청불): 범죄 행위를 직접 묘사하였으며 이용자가 개입하여 플레이 가능',
              id: 979,
              type: '01',
              choice: [
                {
                  next: 'END',
                  no: null,
                  img_path: null,
                  name: '경미한 수준',
                  id: 152,
                  ex_cntn: null
                },
                {
                  next: 'END',
                  no: null,
                  img_path: null,
                  name: '제한적 수준',
                  id: 153,
                  ex_cntn: null
                },
                {
                  next: 'END',
                  no: null,
                  img_path: null,
                  name: '직접적 수준',
                  id: 154,
                  ex_cntn: null
                }
              ]
            }
          ],
          description:
            '범죄 묘사 및 범죄 모방 심리를 부추기거나 플레이어가 범죄 행위에 개입할 수 있는 정도',
          id: '05CO000020',
          title: '범죄 묘사 및 개입 정도'
        }
      ],
      id: 17,
      category: '범죄'
    }
  ],
  questionnaire_contents:
    " ※ 본 설문은 게임물의 연령등급을 결정하기 위한 것으로서, 신청 게임물에 대해 아래 각 항목의 해당 여부를 신중히 검토하여 체크해 주시기 바랍니다.\n  ◎ 게임산업진흥에 관한 법률 제21조 제2항에 따라 게임물의 연령등급은 다음과 같이 구분됩니다.\n    - 전체이용가: 누구나 이용할 수 있는 게임물로서, 청소년에게 유해한 요소가 없는 게임물\n    - 12세이용가: 12세 미만은 이용할 수 없는 게임물로서, 청소년에게 유해한 요소가 경미하게 표현된 게임물\n    - 15세이용가: 15세 미만은 이용할 수 없는 게임물로서, 청소년에게 유해한 요소가 간접적 또는 제한적으로 표현된 게임물\n    - 청소년이용불가: 18세 미만(단, 고등학교 재학생 포함)은 이용할 수 없는 게임물로서, 청소년 유해요소가 직접적이고 사실적으로 표현된 게임물\n  ◎ 각 보기별 사례는 해당 링크를 클릭하여 확인하실 수 있습니다.\n  ◎ 최종 설문의 결과가 '청소년이용불가' 또는 '등급분류 거부'에 해당하는 경우, 게임물관리위원회에 등급분류를 신청해주시기 바랍니다.",
  questionnaire_id: 7,
  created_at: 1649317351000,
  language: 'ko',
  published: 'Y',
  version: '1.0',
  questionnaire_name: '게임물 자체 등급분류 신청 설문',
  survey_no: 2,
  modified_at: 1735802323000,
  create_member_no: 20000674050
};

export const SURVEY_MOCK = {
  surveyNo: 1,
  createMemberNo: 100052426,
  createdAt: '2022-09-21 01:07:10',
  language: 'en',
  modifiedAt: '2023-08-30 06:32:29',
  modifyMemberNo: null,
  published: 'N',
  questionnaireId: 11,
  questionnaireName:
    'Questionnaire for Application of the Independent rating Classification of game products',
  version: '1.0',
  serviceCode: 'INDIE',
  surveyGroupId: '11_1.0',
  questionnaireContents:
    "※ This questionnaire aims at determining the age ratings of the game products, and therefore, please carefully chek up as to whther the applied game products fall in each of the following items.\r\n  ◎ The age ratings of the game products shall be classified according to the paragraph ② of the Article 21 of the Law about the Promotion of the Game Industry as follows:\r\n    - Permitted for use by all: game products in which there is no factor that is detrimental to adolescents and which anyone can use    \r\n    - Permitted for use by 12 year olds: game products in which the factors detrimental to adolescents are expressed in a minor level and which children less than 12 cannot use\r\n    - Permitted for use by 15 year olds: game products in which the factors detrimental to adolescents are expressed in an indirect or restrictive way and which adolescents less than 15 cannot use\r\n    - Not permitted for use by juveniles: game products in which the factors detrimental to adolescents are expressed in a direct and realistic way and which adolescents less than 18 cannot use (but, including high school students)\r\n  ◎ Please click the links concerned to confirm each example.\r\n  ◎ If the final results fall in 'Not permitted for use by juveniles' or 'Rejection of rating Classification,' please file an application for classification before the game products Management Committee.",
  questionnaire: [
    {
      no: '0.',
      contents: [
        {
          no: '0.1.',
          contents: [
            {
              no: '0.1.1.',
              question:
                'Do you have a legitimate title such as copyright, distribution right, portrait right, etc. for the game products and the contents included in the game products?',
              description: null,
              id: 985,
              type: '01',
              choice: [
                {
                  next: '986',
                  no: null,
                  imgPath: null,
                  name: 'Yes. I have.',
                  id: 171,
                  exCntn: null
                },
                {
                  next: null,
                  no: null,
                  imgPath: null,
                  name: "No. I haven't.",
                  id: 172,
                  exCntn: null
                }
              ]
            },
            {
              no: '0.1.2.',
              question:
                'Please check up the service mode of the game products that you applied for.',
              description: null,
              id: 986,
              type: '02',
              choice: [
                {
                  next: '987',
                  no: null,
                  imgPath: null,
                  name: 'Service through the Internet access (e.g. account login through websites, installation of a client or streaming, etc.)',
                  id: 173,
                  exCntn: null
                },
                {
                  next: '987',
                  no: null,
                  imgPath: null,
                  name: 'Service using the dedicated network terminals (e.g. PlayStation Network, XBOX Live, etc.)',
                  id: 174,
                  exCntn: null
                },
                {
                  next: '987',
                  no: null,
                  imgPath: null,
                  name: 'Mobile service (e.g. Open market, including Google Play, etc.)',
                  id: 175,
                  exCntn: null
                },
                {
                  next: '987',
                  no: null,
                  imgPath: null,
                  name: 'Offline package',
                  id: 176,
                  exCntn: null
                },
                { next: '987', no: null, imgPath: null, name: 'Others', id: 177, exCntn: null }
              ]
            },
            {
              no: '0.1.3.',
              question: 'Is there a paid purchasing factor within the game?',
              description: null,
              id: 987,
              type: '01',
              choice: [
                { next: '988', no: null, imgPath: null, name: 'Yes', id: 178, exCntn: null },
                { next: '991', no: null, imgPath: null, name: 'No', id: 179, exCntn: null }
              ]
            },
            {
              no: '0.1.3.1.',
              question: 'Please check up the system of this paid purchasing factor.',
              description: null,
              id: 988,
              type: '01',
              choice: [
                {
                  next: '991',
                  no: null,
                  imgPath: null,
                  name: 'Flat rate (account paid settlement) system',
                  id: 180,
                  exCntn: null
                },
                {
                  next: '991',
                  no: null,
                  imgPath: null,
                  name: 'Partial paid system',
                  id: 181,
                  exCntn: null
                },
                {
                  next: '991',
                  no: null,
                  imgPath: null,
                  name: 'Package sales system',
                  id: 182,
                  exCntn: null
                },
                { next: '991', no: null, imgPath: null, name: 'Others', id: 183, exCntn: null }
              ]
            }
          ],
          description:
            'Confirmation as to whether the applicant has a legitimate title for the applied game products, and as to the service modes and paid models',
          id: '05CO000021',
          title: 'Confirmation as to the retention of a legitimate title and its provision modes '
        },
        {
          no: '0.2.',
          contents: [
            {
              no: '0.2.1.',
              question:
                "Please confirm that a decision of the Independent rating classification of the applied game products may be classified again or cancelled under the Article 21(8) of the Law about the Promotion of the Game Industry, if it is judged that those game products fall in the rating of 'Not permitted for use by juveniles' or they are an object of the 'Rejection of Classification' according to the Article 22 ② of the same law.",
              description: null,
              id: 991,
              type: '01',
              choice: [
                {
                  next: '992',
                  no: null,
                  imgPath: null,
                  name: 'Yes. I have confirmed it.',
                  id: 184,
                  exCntn: null
                }
              ]
            },
            {
              no: '0.2.2.',
              question:
                'Please confirm that the classification of the applied game products may be adjusted under the Artilce 21(9) of the Law about the Promotion of the Game Industry, if the classification results of those game products substantially go against the agreed classification criteria or are different from those of other self-classficiation business operators.',
              description: null,
              id: 992,
              type: '01',
              choice: [
                {
                  next: '993',
                  no: null,
                  imgPath: null,
                  name: 'Yes. I have confirmed it.',
                  id: 185,
                  exCntn: null
                }
              ]
            },
            {
              no: '0.2.3.',
              question:
                'Do the applied game products fall in the speculative mentality game products which may include such content as betting or dividend, or may cause profits or losses in property due to the results incurred by an accidental method?',
              description: null,
              id: 993,
              type: '01',
              choice: [
                { next: null, no: null, imgPath: null, name: 'Yes', id: 186, exCntn: null },
                { next: '994', no: null, imgPath: null, name: 'No', id: 187, exCntn: null }
              ]
            }
          ],
          description:
            'Confirmation as to the forbidden clauses for using and providing the game products for different uses from the classified contents or as a means of gambling',
          id: '05CO000022',
          title:
            'Confirmation as to the prohibition of providing the game products for alteration and speculative behaviors'
        },
        {
          no: '0.3.',
          contents: [
            {
              no: '0.3.1.',
              question:
                'Is there such expressions as crime, violence, obsceness, etc. within the contents of the applied game products which may substantially damage the national identity, harm the beautiful and fine custom such as family ethics, etc., or disorganize the order of the Society under the Law about the Promotion of the Game Industry?',
              description: null,
              id: 994,
              type: '01',
              choice: [
                { next: null, no: null, imgPath: null, name: 'Yes', id: 188, exCntn: null },
                { next: '995', no: null, imgPath: null, name: 'No', id: 189, exCntn: null }
              ]
            }
          ],
          description:
            'Confirmation as to the clauses related to the game products whose manufacturing and bringing in are prohibited thereunder.',
          id: '05CO000023',
          title: 'Confirmation as to the prohibition of the distribution of illegal game products'
        }
      ],
      id: 20,
      category: 'Pre-Questionnaire'
    },
    {
      no: '1.',
      contents: [
        {
          no: '1.1.',
          contents: [
            {
              no: '1.1.1.',
              question:
                'Is there any violent expression against the characters in the human form and living organisams?',
              description:
                'Note) Real humans and characters in the human form, monsters(e.g. Harpy, Centaur) are included.',
              id: 995,
              type: '01',
              choice: [
                { next: '996', no: null, imgPath: null, name: 'Yes', id: 190, exCntn: null },
                { next: '997', no: null, imgPath: null, name: 'No', id: 191, exCntn: null }
              ]
            },
            {
              no: '1.1.1.1.',
              question:
                'Please check up the extent of violent expressions against these characters in the human form and organisms.',
              description:
                'Note) Simple and cartoonish(the whole): Where the violence for living organismas is expressed in a cartoonish way or is expressed into a simple effect according to blows / Minor (Children aged 12): Where the violence for living organisms is expressed in an unrealistic and minor level / Realistic (Adolescents aged 15): Where the attack expressions are realistic, but not excessive / Excessive (Not permitted for use by juveniles): Where the description about the violence for living organisms is specific, excessive and continuous',
              id: 996,
              type: '01',
              choice: [
                {
                  next: '997',
                  no: null,
                  imgPath: null,
                  name: 'Simple and cartoonish level',
                  id: 192,
                  exCntn:
                    'Where a scene to strike and beat an enemy in the human form is expressed into an unrealistic and simple effect in a cross scroll action game'
                },
                {
                  next: '997',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 193,
                  exCntn:
                    'Where minor leveled violence is expressed against living organisms through a combat scene between a cavalry regiment and an artillery unit in a strategic simulation game'
                },
                {
                  next: '997',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 194,
                  exCntn:
                    'Where a hand-to-hand fight expression between those characters which are very similar to real humans appears realistically in a competition fight game'
                },
                {
                  next: '997',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 195,
                  exCntn:
                    'Where there is an expression in which living organisms in the human form (zombies) are killed indiscriminately in an action game'
                }
              ]
            },
            {
              no: '1.1.2.',
              question: 'Is there any violent expression against objects?',
              description:
                'Note) Any and all inanimate objects such as robots, flight vehicles, combat planes, automobiles, etc. are included.',
              id: 997,
              type: '01',
              choice: [
                { next: '998', no: null, imgPath: null, name: 'Yes', id: 196, exCntn: null },
                { next: '999', no: null, imgPath: null, name: 'No', id: 197, exCntn: null }
              ]
            },
            {
              no: '1.1.2.1.',
              question: 'Please check up the extent of violent expressions against these objects.',
              description:
                'Note) Simple and cartoonish(the whole): Where the violence for living organismas is expressed in a cartoonish way or is expressed into a simple effect according to blows / Minor (Children aged 12): Where there is violence for things, but it is expressed in a comparatively minor level.',
              id: 998,
              type: '01',
              choice: [
                {
                  next: '999',
                  no: null,
                  imgPath: null,
                  name: 'Simple and cartoonish level',
                  id: 198,
                  exCntn:
                    'Where there is an expression that the player shoots down the enemy flight vehicles through projectiles in an flight shooting game'
                },
                {
                  next: '999',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 199,
                  exCntn:
                    'Where a scene in which a car is damaged in case of colliding with other cars is expressed in a car racing game'
                }
              ]
            },
            {
              no: '1.1.3.',
              question: 'Is there any expression about weapons and attacks? ',
              description:
                'Note) This includes any and all expressions about weapons and attacks including such weapons as swords/knives, spears, bows,  blunt weapons, sticks, etc. and magic in a fantasy setting, skill attacks and deadly weapons in the real world, and guns.',
              id: 999,
              type: '01',
              choice: [
                { next: '1000', no: null, imgPath: null, name: 'Yes', id: 200, exCntn: null },
                { next: '1002', no: null, imgPath: null, name: 'No', id: 201, exCntn: null }
              ]
            },
            {
              no: '1.1.3.1.',
              question: 'Please check up the extent to which weapons are expressed therein.',
              description:
                'Note) Simple and cartoonish(the whole): Where weapons are expressed in a cartoonish and simple way / Minor (Children aged 12): Where weapons are expressed somewhat sharply / Realistic (Adolescents aged 15): Where weapons are expressed as realistically as possible.',
              id: 1000,
              type: '01',
              choice: [
                {
                  next: '1001',
                  no: null,
                  imgPath: null,
                  name: 'Simple and cartoonish level',
                  id: 202,
                  exCntn:
                    'Where the expressions of weapons on the whole are cartoonish, simple and caricatured in a role playing game'
                },
                {
                  next: '1001',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 203,
                  exCntn:
                    'Where weapons are somewhat sharp and the blowing effect of attacking the enemy is expressed more specifically compared with others in an action game'
                },
                {
                  next: '1001',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 204,
                  exCntn:
                    'Where weapons are expressed as real as possible and blowing actions are expressed realistically in an action game'
                }
              ]
            },
            {
              no: '1.1.3.2.',
              question:
                'Please check up the extent of expressions both in case of attacks and in case of being shot.',
              description:
                'Note) Simple and cartoonish(the whole): Where an attack is expressed into a simple effect / Minor(children aged 12: Where an attack is expressed in an unrealistic and minor level  / Realistic(Adolescents aged 15: Where an expression of attack is realistic, but is not excessive / Excessive(Not permitted for use by juveniles): Where an attack is expressed in a specific, continuous and excessive way',
              id: 1001,
              type: '01',
              choice: [
                {
                  next: '1002',
                  no: null,
                  imgPath: null,
                  name: 'Simple and cartoonish level',
                  id: 205,
                  exCntn:
                    'Where the effects of shooting the monsters and of being shot are expressed into  simple effects in a role-playing game'
                },
                {
                  next: '1002',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 206,
                  exCntn:
                    'Where the process how the player attacks a number of enemies is somewhat exaggerated, but is expressed in a minor level in an action game'
                },
                {
                  next: '1002',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 207,
                  exCntn:
                    'Where the expressions of beating a number of enemies in the human form in a lump and those of being shot from them appear realistically in an action game'
                },
                {
                  next: '1002',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 208,
                  exCntn:
                    'Where the expressions of beating the body between the characters in the human form and those of fresh blood are displayed specifically /continuously and excessively in an action game'
                }
              ]
            }
          ],
          description:
            'Description about the violents for humans or non-humans, the extent to which violence is expressed in case of using weapons and in case of attacks and being shot ',
          id: '05CO000024',
          title: 'Expressions about weapons and attack'
        },
        {
          no: '1.2.',
          contents: [
            {
              no: '1.2.1.',
              question: 'Is there any expression of fresh blood due to blow and being shot?',
              description:
                'Note) This means the expressions about all kinds of blood regardless of color.',
              id: 1002,
              type: '01',
              choice: [
                { next: '1003', no: null, imgPath: null, name: 'Yes', id: 209, exCntn: null },
                { next: '1004', no: null, imgPath: null, name: 'No', id: 210, exCntn: null }
              ]
            },
            {
              no: '1.2.1.1.',
              question: 'Please check up the extent to which the fresh blood is described.',
              description:
                'Note) Minor(Children aged 12): Where the fresh blood is expressed in a cartoonish way, but is not expressed specifically / Realistic(Adolescents aged 15): Where there is a description about realistic bloodstain and fresh blood, but is not excessive / Excessive(Not avaliable to adolescents): Where the red fresh blood is expressed in a specific and continous way',
              id: 1003,
              type: '01',
              choice: [
                {
                  next: '1004',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 211,
                  exCntn:
                    'Where there is an expression which suggests  slightly fresh blood on the body in case of blows between the characters in a role playing game'
                },
                {
                  next: '1004',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 212,
                  exCntn:
                    'Where, in case of dispatching the monsters,  fresh blood is expressed, but it is not excessive and not repetitious/continuous '
                },
                {
                  next: '1004',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 213,
                  exCntn:
                    'Where, in case of shooting humans and of being shot, red fresh blood is displayed specifically and continuously in an online FPS game'
                }
              ]
            }
          ],
          description:
            'Expressions regarding all kinds of blood such as the fresh blood effect and bloodstaine due to blows and being shot',
          id: '05CO000025',
          title: 'Expressions about fresh blood'
        },
        {
          no: '1.3.',
          contents: [
            {
              no: '1.3.1.',
              question:
                'Is there any expression of the damaged body and dead body of a living organism due to attacks?',
              description:
                'Note) This does not include the case where any damaged body and dead body of a living organism is expressed in a cartoonish, simple and caricatured way.',
              id: 1004,
              type: '01',
              choice: [
                { next: '1005', no: null, imgPath: null, name: 'Yes', id: 214, exCntn: null },
                {
                  next: '1006',
                  no: null,
                  imgPath: null,
                  name: 'No',
                  id: 215,
                  exCntn:
                    'Where the effect that a caricatured character in the human form imply disappear in case of being shot is displayed in an action game'
                }
              ]
            },
            {
              no: '1.3.1.1.',
              question:
                'Please check up the extent to which any damaged body and dead body of a living organism is expressed therein.',
              description:
                'Note) Minor(Children aged 12): Where any damaged body and dead body of a living organism is expressed in a cartoonish way, or it only appears on a simple background / Realistic(Adolescents aged 15): Where there is a realistic damage of a dead body and an expression about it, but it is not excessive / Excessive(Not permitted for use by juveniles): Where any damaged body and dead body is expressed in a specific, realistic and continuous way',
              id: 1005,
              type: '01',
              choice: [
                {
                  next: '1006',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 216,
                  exCntn:
                    'Where the damage of a body when a character in the human form dies is expressed in a cartoon form and it is judged as being in a minor level in a cross-scroll action game'
                },
                {
                  next: '1006',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 217,
                  exCntn:
                    'Where an expression about the death of a character in the human form according to  shootings is realistic but not excessive in a third person TPS game'
                },
                {
                  next: '1006',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 218,
                  exCntn:
                    'Where the damage of a body such as a broken neck in the course of shooting the zombies in the human form is expressed specifically and continuously in a horror action game'
                }
              ]
            }
          ],
          description:
            'The extent to which a part of the damaged body of a living organism is expressed and described such as being cut off or burnt due to attacks',
          id: '05CO000026',
          title: 'Descriptions about the damaged body and dead body'
        },
        {
          no: '1.4.',
          contents: [
            {
              no: '1.4.1.',
              question:
                'Do the applied game products fall in this kind of game and is there any factor of plunder within the game products?',
              description:
                'Note) Where it is possible for one user to attack the other user\territory and take a certain kind of resources(e.g. gold, lumber, ores, etc.). Where a user takes the other user\resources in a form of damaging them, it is considered as being a loss that falls in the as described here, but where a user successfully takes the other user\resources and the system provides him with some profits, it is not considered as being a loss that falls in the  as described here.',
              id: 1006,
              type: '01',
              choice: [
                { next: '1007', no: null, imgPath: null, name: 'Yes', id: 219, exCntn: null },
                { next: '1008', no: null, imgPath: null, name: 'No', id: 220, exCntn: null }
              ]
            },
            {
              no: '1.4.1.1.',
              question:
                'Please check up the extent of a factor to which these resources are plundered therein.',
              description:
                'Note) Simple(the whole): Where a user invades the other user\territory, the invasion is made not in the form of  though it may cause some losses from its resources / Minor(Children aged 12): Where a user invades the other user\territory, the invasuon causes some losses in the form of plunder, but there is a proper leveled protective device(e.g. Where a user plunders the other user\territory, the other party will be protected for a period of time so that he or she cannot be attacked and the plunder of the resources will be limited to some extent.)',
              id: 1007,
              type: '01',
              choice: [
                {
                  next: '1008',
                  no: null,
                  imgPath: null,
                  name: 'Simple level',
                  id: 221,
                  exCntn: null
                },
                {
                  next: '1008',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 222,
                  exCntn:
                    'Where a loss in the form of plunder can be incurred when a user is attacked from the other user in a strategic mobile game to construct and manage the territory'
                }
              ]
            }
          ],
          description:
            'Behaviors through which one user invades and takes the other user\territory in a stragegic game based on the social relation network or a web-based strategic game.',
          id: '05CO000027',
          title: 'Factors of Resources Plunder'
        },
        {
          no: '1.5.',
          contents: [
            {
              no: '1.5.1.',
              question:
                'Do the applied game products fall in this kind of game and is there any factor of contest between the characters of the two users within the game products?',
              description:
                'Note) This means a ranking contest, a battle which can be made in a certain area, a confused fight, unilateral player killing, and all the other forms of the player vs player factors.',
              id: 1008,
              type: '01',
              choice: [
                { next: '1009', no: null, imgPath: null, name: 'Yes', id: 223, exCntn: null },
                { next: '1010', no: null, imgPath: null, name: 'No', id: 224, exCntn: null }
              ]
            },
            {
              no: '1.5.1.1.',
              question:
                'Please check up the extent of losses incurred as a result of this contest.',
              description:
                'Note) Simple(the whole): Where it is possible for a user to contest with the other user, but there is no loss between them / Minor(Children aged 12): Where there are minor compensations and losses due to the contest / Restrictive(Adolescents aged 15): Where there are some losses not in the form of plunder and PvP safety device (The safe area, limitation of levels, etc.) / Excessive(Not permitted for use by juveniles): Where it is possible for a user to do a unilateral PK agains the other user and there is a loss in the form of plunder, such as taking the items/experience from the loser.',
              id: 1009,
              type: '01',
              choice: [
                {
                  next: '1010',
                  no: null,
                  imgPath: null,
                  name: 'Simple level',
                  id: 225,
                  exCntn: null
                },
                {
                  next: '1010',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 226,
                  exCntn:
                    'Where there is a competition among the users and minor rewards are given to each user according to his or her ranking points or reputations, etc.'
                },
                {
                  next: '1010',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 227,
                  exCntn:
                    'Where a limited PvP is possible only in a certain area, but there is no loss in case of a failure, with a safety device in a role playing game'
                },
                {
                  next: '1010',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 228,
                  exCntn:
                    'Where a user can obtain an item of the opponent which has been dropped when he or she unilaterally attacks and then puts it to death in'
                }
              ]
            }
          ],
          description:
            'Behaviors through which a user contests with a character of the other user, using a play character in an online MMORPG.',
          id: '05CO000028',
          title: 'Contest Factors Between Users'
        }
      ],
      id: 21,
      category: 'Violence'
    },
    {
      no: '2.',
      contents: [
        {
          no: '2.1.',
          contents: [
            {
              no: '2.1.1.',
              question:
                'Is there any expressions about the sensational garment such as the expressions of exposure more than a minor level including a charactershoulders, waists, legs, etc., tight garments, etc.',
              description:
                'Note) Where a characterbodily exposure is expressed in a minor level, regardless of sexual context / Where the character plainly wears a tight garment, though there is little exposure.',
              id: 1010,
              type: '01',
              choice: [
                { next: '1011', no: null, imgPath: null, name: 'Yes', id: 229, exCntn: null },
                { next: '1012', no: null, imgPath: null, name: 'No', id: 230, exCntn: null }
              ]
            },
            {
              no: '2.1.1.1.',
              question:
                'Please check up the extent to which the expression of exposure by this character and its sensational garment are expressed therein.',
              description:
                'Note) Minor(Children aged 12): Where the bodily exposure is expressed in a minor level, regardless of sexual context(e.g. shoulders, thighs, legs, etc.) / Restrictive(Adolescents aged 15): Where there is an expression of the garment which may plainly suggest sex such as tight garments, etc., or where there is a description with a sexual emphasis on a certain part of the body though it is not exposed directly(e.g. motions of breast, an excessive exposure of a part of the buttocks, etc.) / Directive(Not permitted for use by juveniles): Where the main parts such as nipples, buttocks, sexual organs are exposed, but they do not describe obsceneness excessively.',
              id: 1011,
              type: '01',
              choice: [
                {
                  next: '1012',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 231,
                  exCntn:
                    'Where a slight exposition of the shoulders and thighs, legs, etc. of a woman character is expressed in an action game'
                },
                {
                  next: '1012',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 232,
                  exCntn:
                    'Where the bust morphing of a woman character wearing a bikini is expressed in a fostering simulation game'
                },
                {
                  next: '1012',
                  no: null,
                  imgPath: null,
                  name: 'Direct level',
                  id: 233,
                  exCntn:
                    'Where a nipple of a woman is exposed in a scene in which the main character is making love with the woman in a role playing game'
                }
              ]
            },
            {
              no: '2.1.2.',
              question:
                'Is there any sensational expression of garment including a characterexposure of underwear?',
              description:
                'Note) Where a character is wearing a swimsuit in a swimming pool, it is not considered as wearing a sensational garment normally, but it shall be judged, considering the level / cirmstances and contexts of the exposure concerned.',
              id: 1012,
              type: '01',
              choice: [
                { next: '1013', no: null, imgPath: null, name: 'Yes', id: 234, exCntn: null },
                { next: '1014', no: null, imgPath: null, name: 'No', id: 235, exCntn: null }
              ]
            },
            {
              no: '2.1.2.1.',
              question:
                'Please check up the frequency of sensational exposures including this kind of underwear.',
              description:
                'Note) Sometimes(Adolescents aged 15): Where there is an expression of the underwear exposures, but it is expressed not excessively in some characters only. / Frequently(Not permitted for use by juveniles): Where the underwear exposures of a character is exposed frequently within the main contents of the game products',
              id: 1013,
              type: '01',
              choice: [
                {
                  next: '1014',
                  no: null,
                  imgPath: null,
                  name: 'Sometimes',
                  id: 236,
                  exCntn:
                    'Where an expression about exposing the underwear of some characters is confirmed in a mobile clicker genre game'
                },
                {
                  next: '1014',
                  no: null,
                  imgPath: null,
                  name: 'Often',
                  id: 237,
                  exCntn:
                    'Where the user can freely watch the exposing expressions or those expressions are exposed continuously according to his or her selection in a casual game'
                }
              ]
            },
            {
              no: '2.1.3.',
              question:
                'Is there any expression such as embracement, a little kiss, etc. which may suggest the contents of sex.',
              description:
                'Note) The embracement, a little kiss, etc. which is not related to a sexual context shall not be considered as suggesting the contents of sex.',
              id: 1014,
              type: '01',
              choice: [
                {
                  next: '1015',
                  no: null,
                  imgPath: null,
                  name: 'Yes',
                  id: 238,
                  exCntn:
                    'Where there is a depiction about a kiss between the characters of a man and a woman in a dating simulation game'
                },
                { next: '1015', no: null, imgPath: null, name: 'No', id: 239, exCntn: null }
              ]
            }
          ],
          description:
            'The expressions about the garment which may suggest/associate the contents of sensational bodily exposure and sex of a character',
          id: '05CO000029',
          title: 'Expressions about the sensational bodily exposure and garments'
        },
        {
          no: '2.2.',
          contents: [
            {
              no: '2.2.1.',
              question:
                'Is there any sensational language which contains a suggestion of sex within the game products?',
              description:
                'Note) This includes the dialogues of a characters expressed from the sexual context, texts or voices which express sex and obsceneness, etc.',
              id: 1015,
              type: '01',
              choice: [
                { next: '1016', no: null, imgPath: null, name: 'Yes', id: 240, exCntn: null },
                { next: '1017', no: null, imgPath: null, name: 'No', id: 241, exCntn: null }
              ]
            },
            {
              no: '2.2.1.1.',
              question:
                'Please check up the extent to which these sensational languages are expressed therein.',
              description:
                'Note) This shall be judged as to wherther or not those sensational languages stimulate the sexual desire of adolescents according to their level of expressions.',
              id: 1016,
              type: '01',
              choice: [
                {
                  next: '1017',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 242,
                  exCntn:
                    'Where a text in which the main characters of a man and a woman show their love such as kiss is described not excessively in a dating simulation game'
                },
                {
                  next: '1017',
                  no: null,
                  imgPath: null,
                  name: 'Direct level',
                  id: 243,
                  exCntn:
                    'Where a reference to the sexual intercourse between the characters of a man and a woman is expressed with a passage in an adventure game'
                }
              ]
            }
          ],
          description: 'Sensational Languages which are expressed as texts or voices',
          id: '05CO000030',
          title: 'Sensational languages'
        },
        {
          no: '2.3.',
          contents: [
            {
              no: '2.3.1.',
              question:
                'Is there any expression of sex between the characters and that suggesting sex?',
              description:
                'Note) This includes sexual intercourse, similar sexual intercourse and caress, and all kinds of expressions which suggest sexual intercourse, regardless of the userparticipation.',
              id: 1017,
              type: '01',
              choice: [
                { next: '1018', no: null, imgPath: null, name: 'Yes', id: 244, exCntn: null },
                { next: '1019', no: null, imgPath: null, name: 'No', id: 245, exCntn: null }
              ]
            },
            {
              no: '2.3.1.1.',
              question:
                'Please check up the extent to which these expresions about sexual intercourse are described therein.',
              description:
                'Note) Restrictive(Adolescents aged 15): Where there is an expression suggesting sexual intercourse, but there is no directive description about it / Directive(Not permitted for use by juveniles): Where it describes about sexual intercourse, but it does not reach the level which excessively describes the obsceneness and then disorganizes the order of the Society from the game products context, considering the factors which appear here',
              id: 1018,
              type: '01',
              choice: [
                {
                  next: '1019',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 246,
                  exCntn:
                    'Where a scene in which a man and a woman make love is expressed restrictively in a game in which the player constructs a virtual human world and lead it'
                },
                {
                  next: '1019',
                  no: null,
                  imgPath: null,
                  name: 'Direct level',
                  id: 247,
                  exCntn:
                    'Where a scene in which a man and a woman make love in the open air is expressed without filtering in a high level of free action game'
                }
              ]
            },
            {
              no: '2.3.2.',
              question:
                'Is there any expression related to sexual discrimination, sexual violence and prostitution within the game products?',
              description: null,
              id: 1019,
              type: '01',
              choice: [
                {
                  next: '1020',
                  no: null,
                  imgPath: null,
                  name: 'Yes',
                  id: 248,
                  exCntn:
                    'Where a scene in which the main character tempts a woman, pays the money and then has a sexual intercourse with her on the street is depicted in a high level of free action game'
                },
                { next: '1020', no: null, imgPath: null, name: 'No', id: 249, exCntn: null }
              ]
            }
          ],
          description:
            'All kinds of sex expressions, those expressions suggesting sex, and other expressions about sexual discrimination, sexual violence and prostition.',
          id: '05CO000031',
          title: 'Expressions about sexual intercourse'
        }
      ],
      id: 22,
      category: 'Sexuality'
    },
    {
      no: '3.',
      contents: [
        {
          no: '3.1.',
          contents: [
            {
              no: '3.1.1.',
              question:
                'Is there any expression that has copied the gambling and  betting/dividend behaviors within the game products?',
              description:
                'Note) There shall be no expression that has copied the speculative behaviors in Permitted for use by all.',
              id: 1020,
              type: '01',
              choice: [
                { next: '1021', no: null, imgPath: null, name: 'Yes', id: 250, exCntn: null },
                { next: '1025', no: null, imgPath: null, name: 'No', id: 251, exCntn: null }
              ]
            },
            {
              no: '3.1.1.1.',
              question:
                'Please check up the extent to which the game products copy these gambling behaviors.',
              description:
                'Note) Minor(Children aged 12): Where there is an expression of a simple form of betting or a content due to chance / Restrictive(Adolescents aged 15): Where there is a content which has copied gamblings within the game products, but exists only as a partial element which are not indispensable / Realistic(Available to adolescents): Where their main contents are those which have copied gamblings',
              id: 1021,
              type: '01',
              choice: [
                {
                  next: '1025',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 252,
                  exCntn:
                    'Where a contingent content in the form of a simple rotating platform, not a main content of the game is included in an action game'
                },
                {
                  next: '1025',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 253,
                  exCntn:
                    'Where the user can enjoy a contingent content such as a rotating platform, not a main factor of the game using the paid goods in a web-based strategic game'
                },
                {
                  next: '1022',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 254,
                  exCntn: null
                }
              ]
            },
            {
              no: '3.1.1.2.',
              question:
                "Do these contents which have copied gambling behaviors fall in the card game products or 'flower cards' playing based on betting and devidend?",
              description:
                'Note) In case of the card game products or playing based on betting and devidend provided throught the information and communications network, they may be an object of the matters to be observed by the game products business operators according to the Annex 2 (8) of the Enforcement Ordinance the Law about the game products Industry according to whetther the game money is sold for free or paying the costs.',
              id: 1022,
              type: '01',
              choice: [
                {
                  next: '1023',
                  no: null,
                  imgPath: null,
                  name: 'Yes',
                  id: 255,
                  exCntn:
                    'Poker and game products such as "Go-Stop" in which the users gain and lose the betting game money according to the competitions among users'
                },
                {
                  next: '1025',
                  no: null,
                  imgPath: null,
                  name: 'No',
                  id: 256,
                  exCntn:
                    'Betting game products, card game products, or game products not "flower cards" (Copies of reels, slot machines, etc.)'
                }
              ]
            },
            {
              no: '3.1.1.3.',
              question:
                'Do these card game products or playing sell the game money as a means of betting with fee? ',
              description:
                'Where a user can purchase the betting game money through a paid settlement of an avartar or item, it shall be governed by the below-indicated Enforcement Ordinance. However, where a business operator does not sell the betting game money with fee, it shall not be governed by the Enforcement Ordinance.',
              id: 1023,
              type: '01',
              choice: [
                { next: '1024', no: null, imgPath: null, name: 'Yes', id: 257, exCntn: null },
                { next: '1025', no: null, imgPath: null, name: 'No', id: 258, exCntn: null }
              ]
            },
            {
              no: '3.1.1.4.',
              question:
                'Do you comply with all the subpargaraphs (a) to (g) of the matters to be observed by the game products business operators as determined in the Annex 2 (8) of the Enforcement Ordinance of the Law about the Promition of the Game Industry?',
              description:
                'Note) A. Purchase limit per one month: 500 thousand won / B. Maximum available game money per one game: 50 thousand won / C. Maximum loss game money per one day: 100 thousand won / D. No selection of the opponent / E. No automatic betting / F. Identification per one year / G. Establishment of a method to protect the users',
              id: 1024,
              type: '01',
              choice: [
                { next: '1025', no: null, imgPath: null, name: 'Yes', id: 259, exCntn: null },
                { next: 'END', no: null, imgPath: null, name: 'No', id: 260, exCntn: null }
              ]
            }
          ],
          description:
            'The extent to which the game products express an actual gambling or copy such speculative behaviors as betting/dividend\\r\\n(betting, rotating platforms, card playing, poker, casino (slot machines, roulette, etc.), reel game products, horse racing(races), including copying all the other speculative mentality game-playing plates)',
          id: '05CO000032',
          title: 'The extent to which the users copy gambling and speculative behaviors'
        },
        {
          no: '3.2.',
          contents: [
            {
              no: '3.2.1.',
              question:
                'Is there any content due to chance and probability in the form of enhancement, enchant or combination of an item within the game products?',
              description:
                'Note) Enhancement: Where the user enhances the level and performance of a certain item by using it on a material item / Enchant: Wher the user handles an item with magic and then improves its experience / Cmbination: Where the user combines two items and then obtines an item with new performance',
              id: 1025,
              type: '01',
              choice: [
                { next: '1026', no: null, imgPath: null, name: 'Yes', id: 261, exCntn: null },
                { next: '1027', no: null, imgPath: null, name: 'No', id: 262, exCntn: null }
              ]
            },
            {
              no: '3.2.1.1.',
              question:
                'Is the user able to obtain the items necessary to use these item enhancement, enchant or comination contents within the game products?',
              description:
                'Note) Within the game productsmeans that the user can obaint an item as a result of participating a game, not in the form of purchase with fee(e.g. Obtainment of an item through hunting monsters)',
              id: 1026,
              type: '01',
              choice: [
                { next: '1027', no: null, imgPath: null, name: 'Yes', id: 263, exCntn: null },
                { next: '1027', no: null, imgPath: null, name: 'No', id: 264, exCntn: null }
              ]
            },
            {
              no: '3.2.2.',
              question:
                'Is there any probability typed item(Gacha, etc.) within the game products?',
              description:
                'Note) Proability typed item (Gacha, etc.): This means a random lot-drawing typed or consumptive item whose performance can be determined randomly when opeing or using it.',
              id: 1027,
              type: '01',
              choice: [
                { next: '1028', no: null, imgPath: null, name: 'Yes', id: 265, exCntn: null },
                { next: '1029', no: null, imgPath: null, name: 'No', id: 266, exCntn: null }
              ]
            },
            {
              no: '3.2.2.1.',
              question:
                'Is the user able to obtain this form of probability-typed item within the game products, and is its probability information disclosed to the user sufficiently?',
              description:
                'Note) Within the game productsmeans that the user can obaint an item as a result of participating a game, not in the form of purchase with fee(e.g. Obtainment of an item through hunting monsters, or through conducting the quests)',
              id: 1028,
              type: '01',
              choice: [
                { next: '1029', no: null, imgPath: null, name: 'Yes', id: 267, exCntn: null },
                { next: '1029', no: null, imgPath: null, name: 'No', id: 268, exCntn: null }
              ]
            }
          ],
          description:
            'Whether or not there are all kinds of items which are determined due to chance and probability such as enhancement, enchant, combination, Gacha, etc., and the matter related to their extent',
          id: '05CO000033',
          title: 'Probability-typed items'
        },
        {
          no: '3.3.',
          contents: [
            {
              no: '3.3.1.',
              question:
                'Is there any system through which the users can transact items with each other by using the cash money that has a similar value to cash?',
              description:
                'Note) Where the user can transact an item with other users by using the cash money which can be purchased by cash(e.g. auction house, exchage, etc.). This is judged as a factor that may hinder the use of a corrct use of the game products by adolescents, considering that a transacting site of game items is designated as a medium that is detrimental to adolescents.',
              id: 1029,
              type: '01',
              choice: [
                { next: '1030', no: null, imgPath: null, name: 'Yes', id: 269, exCntn: null },
                { next: '1030', no: null, imgPath: null, name: 'No', id: 270, exCntn: null }
              ]
            }
          ],
          description: 'Other matters related to the speculative mentality',
          id: '05CO000034',
          title: 'Speculative mentality'
        }
      ],
      id: 23,
      category: 'Speculative mentality'
    },
    {
      no: '4.',
      contents: [
        {
          no: '4.1.',
          contents: [
            {
              no: '4.1.1.',
              question:
                'Is there any factor that may cause the user to feel threatened or feared through the backgrounds, images, sounds, pictures, etc. within the game products?',
              description: null,
              id: 1030,
              type: '01',
              choice: [
                { next: '1031', no: null, imgPath: null, name: 'Yes', id: 271, exCntn: null },
                { next: '1032', no: null, imgPath: null, name: 'No', id: 272, exCntn: null }
              ]
            },
            {
              no: '4.1.1.1.',
              question:
                'Please check up the extent to which the factor may cause this kind of threat or fear of the user.',
              description:
                'Note) Suggestive(the whole): Where the factor is expressed into a simple background only / Minor(Children aged 12): Where the factor produces a bleak and dark atmospher, but it is not realistic / Realistic(Adolescents aged 15): Where there is a factor that may cause the user to feel threatened or feared, but it is not excessive / Excessive(Not permitted for use by juveniles): Where the factor that may cause horror is specific and so it cuases a psychological tension and discomfort continuously.',
              id: 1031,
              type: '01',
              choice: [
                {
                  next: '1032',
                  no: null,
                  imgPath: null,
                  name: 'Suggestive level',
                  id: 273,
                  exCntn:
                    'Where the whole background is expressed darkly and dingily in a puzzle adventure game'
                },
                {
                  next: '1032',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 274,
                  exCntn:
                    'Where the game environment in which the users can interact with each other is expressed darkly and dingily, but it is not unrealistic in an action game'
                },
                {
                  next: '1032',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 275,
                  exCntn:
                    'Where a scene of causing a sensation of fear is described with  comparatively realistic expressions according to the flow of the game in a horror adventure game'
                },
                {
                  next: '1032',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 276,
                  exCntn:
                    'Where a factor of causing a continuous sensation of horror and fear to the user is expressed specifically and excessively in a horror action game'
                }
              ]
            }
          ],
          description:
            'The extent to which the user feels threatened or feared through the backgrounds, images, sounds, pictures, etc.',
          id: '05CO000035',
          title: 'Factors that cause fear and horror'
        },
        {
          no: '4.2.',
          contents: [
            {
              no: '4.2.1.',
              question:
                'Is there any factor that cause the user feel disgusted through the backgrounds, images, sounds, pictures, etc.?',
              description: null,
              id: 1032,
              type: '01',
              choice: [
                { next: '1033', no: null, imgPath: null, name: 'Yes', id: 277, exCntn: null },
                { next: '1034', no: null, imgPath: null, name: 'No', id: 278, exCntn: null }
              ]
            },
            {
              no: '4.2.1.1.',
              question:
                'Please check up the extent to which the factor may cause this kind of disgust to the user.',
              description:
                'Note) Suggestive(the whole): Where the factor is expressed into a simple background only / Minor(Children aged 12): Where there is a factor that may cause the user to feel hatred, but it is not realistic / Realistic(Adolescents aged 15): Where there is a factor that may cause the user to feel hatred, but it is not excessive / Excessive(Not permitted for use by juveniles): Where the factor that may cause the user to feel hatred is expressed specifically and plainly.',
              id: 1033,
              type: '01',
              choice: [
                {
                  next: '1034',
                  no: null,
                  imgPath: null,
                  name: 'Suggestive level',
                  id: 279,
                  exCntn:
                    'Where an expression of a body is described blurredly as a simple background in a puzzle adventure game'
                },
                {
                  next: '1034',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 280,
                  exCntn:
                    'Where there is a description about a strange form of skull and it causes aversion in a puzzle game according to the progress of the story'
                },
                {
                  next: '1034',
                  no: null,
                  imgPath: null,
                  name: 'Realistic level',
                  id: 281,
                  exCntn:
                    'Where an image of a grotesque character is expressed in some intermediate images according to the progress of the story in an action game'
                },
                {
                  next: '1034',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 282,
                  exCntn:
                    'Where the internal organs of an infected monster are depicted and they cause a continuous and specific aversion in a horror action game'
                }
              ]
            }
          ],
          description:
            'The extent to which the user feels hatred or disgusted through the backgrounds, images, sounds, pictures, etc.',
          id: '05CO000036',
          title: 'Factors that cause aversion'
        }
      ],
      id: 24,
      category: 'Horror'
    },
    {
      no: '5.',
      contents: [
        {
          no: '5.1.',
          contents: [
            {
              no: '5.1.1.',
              question:
                'Are there any vulgar jokes, slangs, vulgarisms, oaths, etc. within the game products?',
              description:
                'Note) English vulgarisms and oaths such as bastard, god damn, asshole, shit, son of a bitch, fuck, motherfucker, and other foreign language oaths and all kinds of localized oaths.',
              id: 1034,
              type: '01',
              choice: [
                { next: '1035', no: null, imgPath: null, name: 'Yes', id: 283, exCntn: null },
                { next: '1036', no: null, imgPath: null, name: 'No', id: 284, exCntn: null }
              ]
            },
            {
              no: '5.1.1.1.',
              question:
                'Please check up the extent to which these vulgar jokes, slangs, vulgarisms, oaths, etc. are expressed therein. ',
              description:
                'Note) Minor(Children aged 12): little oaths, ordinary slangs, Internet jargons, etc.(Damn it, Asshole) / Restrictive(Adolescents aged 15): Where there are oaths expressions, but they do not appear repetitiously and reach the level that is acceptable to the persons aged 15 and above(e.g. Damn it, a crazy guy, son of a bitch, what a sight, Fucking head) / Excessive(Not permitted for use by juveniles): Where the direct, specific or repetitious oaths may have a very negative effect on a sound language habit of adolescents',
              id: 1035,
              type: '01',
              choice: [
                {
                  next: '1036',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 285,
                  exCntn:
                    'Where some mild oaths such as  etc. are expressed in a dating simulation game'
                },
                {
                  next: '1036',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 286,
                  exCntn:
                    'Where there are coarse language expressions such as etc. in some lines of the main character according to the progress of the story in an action adventure game'
                },
                {
                  next: '1036',
                  no: null,
                  imgPath: null,
                  name: 'Excessive level',
                  id: 287,
                  exCntn:
                    'Where straightforward abusive languages are expressed without any filtering and so it is judged that they may be harmful to adolescents according to the progress of the story'
                }
              ]
            }
          ],
          description:
            'Improper language expressions that may hinder a sound language life of adolescents as an insulting, rude and discriminative language(including the dialogues, voices and interim cinematic images of a chracter)',
          id: '05CO000037',
          title: 'Improper language expressions'
        }
      ],
      id: 25,
      category: 'Language'
    },
    {
      no: '6.',
      contents: [
        {
          no: '6.1.',
          contents: [
            {
              no: '6.1.1.',
              question:
                'Is there any expression related to drinking of alcohols and alcoholic beverges or smoking of cigarettes within the game products?',
              description:
                'Note) This includes the expressions about the drinking and smoking of NPC(Non-player character).',
              id: 1036,
              type: '01',
              choice: [
                { next: '1037', no: null, imgPath: null, name: 'Yes', id: 288, exCntn: null },
                { next: '1038', no: null, imgPath: null, name: 'No', id: 289, exCntn: null }
              ]
            },
            {
              no: '6.1.1.1.',
              question:
                'Please check up the extent to which these kinds of drinking and smoking expressions.',
              description:
                'Note) Minor(Children aged 12): Where the expressions appear as a simple background, or where they cannot interact with each other / Restricive(Adolescents aged 15): Where it is possible to use the expressions of drinking and smoking according to the selection of users as a content of little importance / Directive(Not permitted for use by juveniles): Where smoking and drinking is expressed directly, or where users freely can interact with each other according to thier will',
              id: 1037,
              type: '01',
              choice: [
                {
                  next: '1038',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 290,
                  exCntn:
                    'Where the content in which a player quickly touches and provides kinds of alcoholic drinks which guests request is expressed in a mobile tycoon typed game.'
                },
                {
                  next: '1038',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 291,
                  exCntn:
                    'Where there is a expression in which a screen blurs after a character drinks according to the selection of the user, but it is expressed restrictively in MMORPG'
                },
                {
                  next: '1038',
                  no: null,
                  imgPath: null,
                  name: 'Direct level',
                  id: 292,
                  exCntn:
                    'Where the main character is allowed to freely smoke a cigarette according to the selection of the character or a drinking scene is expressed directly/specifically in an action game'
                }
              ]
            }
          ],
          description:
            'All kinds of expressions related to drinking and smoking and the extent to which users can participated therein.',
          id: '05CO000038',
          title: 'Expressions about drinking and smoking'
        },
        {
          no: '6.2.',
          contents: [
            {
              no: '6.2.1.',
              question: 'Is there any expression related to drugs within the game products?',
              description:
                'This means all kinds of drugs usage and transactions which are expressed within the game products, inclduing the behaviors of NPC and players.',
              id: 1038,
              type: '01',
              choice: [
                {
                  next: '1039',
                  no: null,
                  imgPath: null,
                  name: 'Yes',
                  id: 300,
                  exCntn:
                    'Where the content in which a player is freely smoking pot or transacting drugs is included in a high level of free action game'
                },
                { next: '1039', no: null, imgPath: null, name: 'No', id: 293, exCntn: null }
              ]
            }
          ],
          description:
            'This means an inhalation of drugs, bonds, gases, etc. that may cause hallucination, or an expression of drugs usage that may cause similar hallucination effects.',
          id: '05CO000039',
          title: 'Expressions about drugs'
        }
      ],
      id: 26,
      category: 'Drugs'
    },
    {
      no: '7.',
      contents: [
        {
          no: '7.1.',
          contents: [
            {
              no: '7.1.1.',
              question: 'Is there a description of a crime within the game?',
              description: 'Note) The crime expressions in NPC are included.',
              id: 1039,
              type: '01',
              choice: [
                { next: '1040', no: null, imgPath: null, name: 'Yes', id: 295, exCntn: null },
                { next: 'END', no: null, imgPath: null, name: 'No', id: 296, exCntn: null }
              ]
            },
            {
              no: '7.1.1.1.',
              question: 'Please check up the extent of description about the crime concerned.',
              description:
                'Note) Minor(Children aged 12): Where the expressions are described into a simple background, regardless of the will of users / Restrictive(Adolescents aged 15): Where the expressions are of little importance and are described in a restrictive way within the game products / Directive(Not avaliable to adolescents): Where the expressions describes a criminal behavior directly and it is possible for users to intervene and play the game products',
              id: 1040,
              type: '01',
              choice: [
                {
                  next: 'END',
                  no: null,
                  imgPath: null,
                  name: 'Minor level',
                  id: 297,
                  exCntn:
                    'Where the main character appears as a train robber in the story setup in a cross scroll action game'
                },
                {
                  next: 'END',
                  no: null,
                  imgPath: null,
                  name: 'Limited level',
                  id: 298,
                  exCntn:
                    'Where arson, theft, etc. is expressed among some quest contents in MMORPG, but they are expressed with not being a big part of the game'
                },
                {
                  next: 'END',
                  no: null,
                  imgPath: null,
                  name: 'Direct level',
                  id: 299,
                  exCntn:
                    'Where there is a criminal expression of robbing a bank, or attacking the police or citizens according to the will of a user in a high level of free action game'
                }
              ]
            }
          ],
          description:
            'The extent to which the expressions may incite a user to describe a crime and commit a copycat crime or to intervene a criminal behavior ',
          id: '05CO000040',
          title: 'The extent to which users copy a crime and intervene it therein'
        }
      ],
      id: 27,
      category: 'Crime'
    }
  ]
};
